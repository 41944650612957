import _ from "lodash";
import React, { Suspense, useState } from "react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import ConfirmationDialog from "../../components/model/ConfirmationDialog";
import PageTitle from "../../components/model/PageTitle";
import TransactionPopUp from "../../components/model/transaction/TransactionPopUp";
import apiClient from "../../service/apiClient";
import {
  AGENT_TRANSFER_APPROVE,
  AGENT_TRANSFER_REJECT,
  AGENT_WITHDRAW_REJECT_TRANSFER,
  AGENT_WITHDRAW_TRANSFER_APPROVE,
  FETCH_AGENT_TRANSFERS,
  FETCH_AGENT_WITHDRAW_TRANSFERS,
  TRANSFER_APPROVE,
  TRANSFER_REJECT,
} from "../../service/apiConstants";

import { StyleSheetManager } from "styled-components";
import Pagination from "../../components/Pagination/Pagination";
import { shouldForwardProp } from "../../service/shouldForwardProp";

// custom style for data table

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {},
  },
};

const AgentWithdrawPayouts = () => {
  const [data, setData] = React.useState([]);

  const [tableDataLoad, setTableDataLoad] = React.useState(true);
  const [load] = React.useState(false);
  const [viewRowData, setViewRowData] = React.useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = React.useState(false);

  const [bankBal, setBankBal] = useState("0");
  const [bankBalLoad, setBankBalLoad] = useState(false);

  // filter through search bar
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // get all RentPayouts from api backend

  //   const getTransfers1 = async () => {
  //     // setTableDataLoad(true);
  //     await apiClient
  //       .get(FETCH_AGENT_WITHDRAW_TRANSFERS)
  //       .then((response) => {
  //         console.log("res======================>", response);
  //         const status = response.status;
  //         const message = response?.data?.message;

  //         if (status === 200) {
  //           setData(response?.data?.response?.agents_array);
  //           console.log("ac=-=========>", response?.data?.response?.agents_array);
  //           setTableDataLoad(false);
  //           return;
  //         } else {
  //           let message = "";
  //           toast(message, {
  //             theme: "dark",
  //             hideProgressBar: true,
  //             type: "success",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         setTableDataLoad(false);
  //         const status = error.response.status;
  //         const message = error.response.data.message;

  //         if (status === 500) {
  //           toast(message, {
  //             theme: "dark",
  //             hideProgressBar: true,
  //             type: "error",
  //           });
  //         }
  //       });
  //   };

  const getTransfers = async () => {
    // setTableDataLoad(true);
    await apiClient
      .get(FETCH_AGENT_WITHDRAW_TRANSFERS)
      .then((response) => {
        const status = response.status;
        const message = response.data.message;

        if (status === 200) {
          setData(response.data.response.Transfer_array);
          setTableDataLoad(false);

          return response.data.response.Transfer_array;
        }

        if (status === 202) {
          setTableDataLoad(false);
          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setTableDataLoad(false);
        const status = error.response.status;
        const message = error.response.data.message;

        if (status === 500) {
          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        }
      });
  };

  const { data: qdata, refetch } = useQuery("repoData", getTransfers, {
    refetchInterval: 5000,
  });

  const approveTransaction = async (transactionRow) => {
    console.log("hello approve", transactionRow);
    setTableDataLoad(true);
    await apiClient
      .post(AGENT_WITHDRAW_TRANSFER_APPROVE, {
        agent_Id: transactionRow?.agent_Id,
        withdrawalId: transactionRow?.withdrawalId,
        accountNumber: transactionRow?.accountNumber,
        ifscCode: transactionRow?.ifscCode,
      })
      .then((response) => {
        setTableDataLoad(false);
        const status = response.status;
        const message = response.data.message;

        if (status === 201) {
          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });

          refetch();
          return;
        }
        toast(message, {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      })
      .catch((error) => {
        setTableDataLoad(false);
        refetch();
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  const rejectTransaction = async (transactionRow) => {
    setTableDataLoad(true);
    await apiClient
      .post(AGENT_WITHDRAW_REJECT_TRANSFER, {
        withdrawalId: transactionRow?.withdrawalId,
      })
      .then((response) => {
        setTableDataLoad(false);
        const status = response.status;
        const message = response.data.message;
        refetch();
        if (status === 201) {
          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });
          return;
        }
        toast(message, {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      })
      .catch((error) => {
        setTableDataLoad(false);
        console.log(error);
        refetch();
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  // const checkbalance = async () => {
  //   setBankBalLoad(true);
  //   await apiClient
  //     .get(CHECK_ACCOUNT_BALANCE)
  //     .then((response) => {
  //       setBankBalLoad(false);
  //       const status = response.status;

  //       if (status === 200) {
  //         setBankBal(response.data.response.avilableBal);
  //       }
  //     })
  //     .catch((error) => {
  //       setBankBalLoad(false);
  //       setBankBal("ERROR OCCURED");

  //       if (!error.status) {
  //         toast("server busy", {
  //           theme: "dark",
  //           hideProgressBar: true,
  //           type: "error",
  //         });
  //         return;
  //       }
  //       const status = error.response.status;
  //       const message = error.response.data.message;

  //       if (status === 400) {
  //         toast(message, {
  //           theme: "dark",
  //           hideProgressBar: true,
  //           type: "error",
  //         });
  //       }
  //     });
  // };

  // useEffect(() => {
  //   checkbalance();
  // }, []);

  // useQuery code

  // setting columns for table view
  const columns = [
    {
      name: "SL",
      cell: (row, index) => Math.abs(row.Index - 1 - filteredData.length),
      width: "80px",
    },

    {
      name: "Agent Id",
      selector: (row) => row.agent_Id,
      width: "150px",
    },

    {
      name: "Amount",
      selector: (row) => "₹" + Number(row?.amount).toFixed(2),
      sortable: true,
      width: "140px",
    },
    {
      name: "Withdrawal Id",
      selector: (row) => row.withdrawalId,
      width: "130px",
    },
    {
      name: "Status",
      width: "120px",
      sortable: true,
      selector: (row) => {
        if (row.status == "APPROVED") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              {row.status}
            </span>
          );
        } else if (row.status == "REJECTED") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
              {row.status}
            </span>
          );
        } else if (row.status == "PROS") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              {"NEFT PROS"}
            </span>
          );
        } else if (row.status == "AWAITING") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-orange-200 text-orange-800">
              {row.status}
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-orange-200 text-orange-800">
              {row.status}
            </span>
          );
        }
      },
      center: true,
    },

    {
      name: "Date",
      selector: (row) => {
        const dateString = row.created_At;
        const dateObject = new Date(dateString);

        // Get the date portion as a string (YYYY-MM-DD)
        const date = dateObject.toISOString().split("T")[0];
        return date;
      },
    },
    // {
    //   name: "Account Status",
    //   selector: (row) => {
    //     if (row?.Customer_id?.disabled === false) {
    //       return (
    //         <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
    //           ACTIVE
    //         </span>
    //       );
    //     } else {
    //       return (
    //         <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
    //           DEACTIVE
    //         </span>
    //       );
    //     }
    //     // return row?.Customer_id?.disabled === false ? "ACTIVE" : "DEACTIVE";
    //   },
    //   width: "150px",
    // },
    {
      name: "Slack_ts",
      selector: (row) => {
        if (row.slack_ts !== "") {
          return row.slack_ts;
        }
        return "Not Available";
      },
      width: "180px",
      center: true,
    },
    {
      name: "Action",
      width: "290px",
      cell: (row) => (
        <>
          <div className="w-full ">
            {/* <button
              className="bg-blue-700 basis-10 hover:bg-blue-600 text-white  py-2 px-2 me-1 rounded"
              onClick={() => {
                setViewRowData({ show: true, data: row });
              }}
            >
              VIEW
            </button> */}

            <button
              className={` ${
                row.status !== "AWAITING"
                  ? "bg-green-300 cursor-not-allowed "
                  : "bg-green-700  hover:bg-green-600 "
              } basis-10 text-white  py-2 px-2 me-1 rounded`}
              onClick={() => {
                row.status === "AWAITING" &&
                  setIsOpenConfirmation({
                    status: true,
                    action: "approve",
                    data: row,
                    message: (
                      <>
                        Are you sure to <strong>APPROVE</strong> This
                        Transaction?
                      </>
                    ),
                  });
              }}
              disabled={row.status !== "AWAITING"}
            >
              APPROVE
            </button>
            <button
              className={` ${
                row.status !== "AWAITING"
                  ? "bg-red-300 cursor-not-allowed"
                  : "bg-red-700  hover:bg-red-600 "
              } basis-10 text-white  py-2 px-2 me-1 rounded`}
              onClick={() => {
                row.status === "AWAITING" &&
                  setIsOpenConfirmation({
                    status: true,
                    action: "reject",
                    data: row,
                    message: (
                      <>
                        Are you sure to <strong>REJECT</strong> This
                        Transaction?
                      </>
                    ),
                  });
              }}
              disabled={row.status !== "AWAITING"}
            >
              REJECT
            </button>
          </div>
        </>
      ),
    },
  ];

  //data filtered logic
  let filteredData = [
    ...data?.map((item, index) => {
      return { ...item, Index: index + 1 };
    }),
  ].reverse();
  return (
    <main className="p-4 w-full">
      <PageTitle title="Agents Withdrawal Transaction Request" />

      {load ? (
        <Loader />
      ) : (
        <>
          {viewRowData?.show ? (
            <TransactionPopUp
              row={viewRowData?.data}
              setIsPopupOpen={() => setViewRowData(false)}
            />
          ) : null}
          <Suspense>
            {/* <div className="grid grid-cols-1 gap-4 px-4 mt-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:px-8 mb-8 w-full">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow p-2">
          <div className="p-4 ">
            <PiBankDuotone size={30} className="text-[#00006B]" />
          </div>
          <div className="px-4 text-gray-700">
            <h3 className="text-sm tracking-wider">A/C Balance</h3>
            {bankBalLoad ? (
              <p className="text-md sm:text-sm md:text-sm">LOADING...</p>
            ) : (
              <p className="text-md sm:text-sm md:text-sm">{bankBal || 0}</p>
            )}
          </div>
          <div className="p-4  cursor-pointer" onClick={() => checkbalance()}>
            <FiRefreshCw size={20} className="text-[#00006B]" />
          </div>
        </div>

   
      </div> */}

            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationComponent={Pagination}
                progressPending={tableDataLoad}
                progressComponent={<Loader />}
                fixedHeader
                customStyles={customStyles}
              />
            </StyleSheetManager>
          </Suspense>
          <ConfirmationDialog
            isOpen={isOpenConfirmation?.status}
            message={isOpenConfirmation?.message}
            onCancel={() => {
              setIsOpenConfirmation(false);
            }}
            onConfirm={() => {
              setIsOpenConfirmation(false);
              if (
                !_.isEmpty(isOpenConfirmation?.data) &&
                isOpenConfirmation?.action === "approve"
              ) {
                approveTransaction(isOpenConfirmation?.data);
              } else if (
                !_.isEmpty(isOpenConfirmation?.data) &&
                isOpenConfirmation?.action === "reject"
              ) {
                rejectTransaction(isOpenConfirmation?.data);
              }
            }}
          />
        </>
      )}
    </main>
  );
};

export default AgentWithdrawPayouts;
