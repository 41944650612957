import React, { useEffect, useState } from "react";
import apiClient from "../../service/apiClient";
import { AGENTS } from "../../service/apiConstants";
import _ from "lodash";

const PersonalInfo = ({ data, setData, validation, setValidation }) => {
  const [tempData, setTempData] = useState([]);
  const [selected, setSelected] = useState("");

  const validateProperty = ({ name, value }) => {
    console.log(name, value);

    if (name === "FullName") {
      if (!value && _.isEmpty(value)) {
        return "Full name is required";
      } else if (value.length < 3) {
        return "Full Name should be minimum 3 characters.";
      } else if (value.trim() === "") {
        return "Full name is required";
      }
    }

    if (name === "mobile") {
      if (!value && _.isEmpty(value)) {
        return "Mobile number is required";
      } else if (value.trim() === "") {
        return "Mobile is required";
      } else if (!/^ *[0-9]{10} *$/.test(value)) {
        return "Invalid mobile number.";
      }
    }

    if (name === "Email") {
      if (!value && _.isEmpty(value)) {
        return "Email is required";
      } else if (value.trim() === "") {
        return "Email is required";
      } else if (!/^ *[\w-\.]+@([\w-]+\.)+[\w-]{2,4} *$/g.test(value)) {
        return "Invalid Email";
      }
    }

    if (name === "Business_Name") {
      if (!value && _.isEmpty(value)) {
        return "Please Enter Your Business Name";
      } else if (value.length < 3) {
        return "Business name should be minimum 3 characters";
      } else if (value.trim() === "") {
        return "Business Name is required";
      }
    }

    if (name === "Pincode") {
      if (!value && _.isEmpty(value)) {
        return "Pincode is required";
      } else if (value.length !== 6) {
        return "Invalid Pincode";
      } else if (!/^\d*$/.test(value)) {
        return "Pincode should be number";
      }
    }

    if (name === "Address") {
      if (!value && _.isEmpty(value)) {
        return "Address is required";
      } else if (value.length < 10) {
        return "Address should be minmum 10 characters.";
      } else if (value.trim() === "") {
        return "Full name is required";
      }
    }
  };

  const handleInputChange = (e) => {
    const errors = { ...validation };
    const errorMessage = validateProperty(e.target);
    if (errorMessage) errors[e.target.name] = errorMessage;
    else errors[e.target.name] = "";

    if (
      e.target.name === "FullName" ||
      e.target.name === "Business_Name" ||
      e.target.name === "Pincode" ||
      e.target.name === "Address"
    ) {
      setData({
        ...data,
        Customer_data: {
          ...data?.Customer_data,
          [e.target.name]: e.target.value,
        },
      });
    } else setData({ ...data, [e.target.name]: e.target.value });

    setValidation({ ...validation, ...errors });
  };

  useEffect(() => {
    async function lastfilled() {
      await apiClient
        .get(AGENTS)
        .then((response) => {
          console.log("response", response);
          setTempData([
            ...tempData,
            ...response?.data?.response?.TempData_array,
          ]);
          // setData(response.data.response.transactions_array);
        })
        .catch((error) => {
          // console.log(error);
        });
      console.log("tempDAta", tempData);
    }
    lastfilled();
  }, []);

  const filteredFilled = (event) => {
    console.log("eventetarget", event.target.value);
    const newData = tempData?.find(
      (lastFilled) => lastFilled._id === event.target.value
    );
    setData({ ...newData, tempDataId: newData._id });
  };

  return (
    <div className="ms-2 mt-6">
      <h2 className="text-lg text-[#45464E] font-medium">
        Add Personal Information
      </h2>
      <div className=" mt-4 min-[1250px]:mt-0">
        <label>Continue with Previous incomplete entry: </label>
        <select
          value={selected}
          onChange={filteredFilled}
          className="w-full min-[950px]:w-auto"
        >
          <option value="">Choose from LastFilled</option>
          {tempData.length > 0 &&
            tempData
              .filter(
                (data) =>
                  data?.Customer_data && data?.Customer_data?.FullName != ""
              )
              .map((data, index) => (
                <option key={index} value={data?._id}>
                  {data?.Customer_data?.FullName} {data.Email}
                </option>
              ))}
          {/* <option>Select Agent Data</option>
          <option>Agent xyz</option> */}
        </select>
      </div>
      <div className="text-sm mt-10  min-[950px]:grid grid-cols-2 gap-y-4 gap-x-16 mx-0 min-[1250px]:mx-28">
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">
            Full Name<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="FullName"
            value={data?.Customer_data?.FullName || ""}
            placeholder="Agent Name"
            onChange={(e) => handleInputChange(e)}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.FullName && (
            <p className="text-red-800">{validation.FullName}</p>
          )}
        </div>
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">
            Mobile Number<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="mobile"
            placeholder="Mobile Number"
            value={data.mobile || ""}
            onChange={(e) => handleInputChange(e)}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.mobile && (
            <p className="text-red-800">{validation.mobile}</p>
          )}
        </div>
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">
            Email<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="Email"
            placeholder="Email Address"
            onChange={(e) => handleInputChange(e)}
            value={data.Email || ""}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.Email && (
            <p className="text-red-800">{validation.Email}</p>
          )}
        </div>
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">
            Business Name<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="Business_Name"
            placeholder="Business Name"
            onChange={(e) => handleInputChange(e)}
            value={data.Customer_data?.Business_Name || ""}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.Business_Name && (
            <p className="text-red-800">{validation.Business_Name}</p>
          )}
        </div>
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">
            Pincode<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="Pincode"
            placeholder="pincode here"
            maxLength={6}
            value={data.Customer_data?.Pincode || ""}
            onChange={(e) => handleInputChange(e)}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.Pincode && (
            <p className="text-red-800">{validation.Pincode}</p>
          )}
        </div>
        <div className="col-span-1 ">
          <label className="block mb-2">
            Address<span className="text-red-500 ">*</span>
          </label>
          <input
            type="text"
            name="Address"
            placeholder="Agents Address here"
            value={data.Customer_data?.Address || ""}
            onChange={(e) => handleInputChange(e)}
            className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
          />
          {validation?.Address && (
            <p className="text-red-800">{validation.Address}</p>
          )}
        </div>
      </div>

      {/* <input type="text" value={data} onChange={handleInputChange} /> */}
    </div>
  );
};

export default PersonalInfo;
