import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../service/apiClient";
import {
  AADHAR_VERIFICATION,
  OTP_VERIFICATION,
  PAN_VERIFICATION,
} from "../../service/apiConstants";
import Loader from "../Loader/Loader";
import _ from "lodash";

const KYCVerification = ({ data, setData, validation, setValidation }) => {
  const [load, setLoad] = useState(false);
  const [verify, setVerify] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  // const [aadhar, setAadhar] = useState("");
  const [aadharError, setAadharError] = useState("");
  const [otp, setOpt] = useState("");
  const [refId, setRefID] = useState("");
  // const [aadharUser, setAadharUser] = useState({});

  const [panError, setPanError] = useState("");
  let aadharRegX = /^ *[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4} *$/;
  let panRegX = /^ *[A-Z]{5}[0-9]{4}[A-Z]{1} *$/;

  useEffect(() => {
    let interval;
    if (isTimerRunning && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsTimerRunning(false);
    }

    return () => clearInterval(interval);
  }, [isTimerRunning, seconds]);

  const handleStartTimer = () => {};

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateProperty = ({ name, value }) => {
    if (name === "aadhar") {
      if (!value && _.isEmpty(value)) {
        return "Aadhar is required";
      } else if (!value?.length === 12) {
        return "Aadhar should be of 12 numbers only";
      } else if (!aadharRegX.test(value)) {
        return "Invalid aadhar";
      }
    }

    if (name === "pan") {
      if (!value && _.isEmpty(value)) {
        return "Pan is required";
      } else if (!panRegX.test(value.toUpperCase())) {
        return "Invalid pan";
      }
    }
  };

  // validating aadhar
  const handleAadharChange = (e) => {
    // setAadhar(e.target.value);
    const errors = { ...validation };
    const errorMessage = validateProperty(e.target);
    if (errorMessage) errors[e.target.name] = errorMessage;
    else errors[e.target.name] = "";
    delete errors["AadhararNo"];
    delete errors["aadhaarVerified"];
    setData({
      ...data,
      kyc_details: {
        ...data.kyc_details,
        Aadhar_Details: {
          ...data?.kyc_details?.Aadhar_Details,
          aadhar: e.target.value,
        },
      },
    });
    setAadharError(errors?.aadhar);
    setValidation({ ...errors });
  };
  const validateAddhar = async () => {
    if (data?.kyc_details?.Aadhar_Details?.aadhar?.length === 0) {
      setAadharError("Please Enter Aadhar");
      return;
    } else {
      setAadharError("");
    }

    if (aadharRegX.test(data?.kyc_details?.Aadhar_Details?.aadhar)) {
      setAadharError("");

      setLoad(true);
      // setAadharUser({});
      try {
        await apiClient
          .post(AADHAR_VERIFICATION, {
            aadhaar_number: data?.kyc_details?.Aadhar_Details?.aadhar.trim(),
          })
          .then((response) => {
            setVerify(true);
            setIsTimerRunning(true);
            setSeconds(30);
            console.log(response);
            let message = response.data.message;

            if (response.data.status) {
              console.log(message);
              // console.log(response.data.response.ref_id);
              setRefID(response.data.response.ref_id);
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "success",
              });
            } else {
              console.log(message);
              setVerify(false);
              setIsTimerRunning(false);

              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "warning",
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            console.log(error);
            let message = error.response.data.message;
            setVerify(false);
            setIsTimerRunning(false);
            toast(message, {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
            setLoad(false);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setAadharError("Enter valid Aadhar");
      return;
    }
  };
  const submitOtp = async () => {
    setLoad(true);
    try {
      await apiClient
        .post(OTP_VERIFICATION, {
          aadhaar_number: data?.kyc_details?.Aadhar_Details?.aadhar,
          otp: otp.trim(),
          ref_id: refId,
        })
        .then((response) => {
          setLoad(false);
          console.log(response);
          let message = response.data.message;

          let responseData = response.data.response;
          // setAadharUser(responseData);
          setData({
            ...data,
            kyc_details: {
              ...data?.kyc_details,
              Aadhar_Details: response.data.response,
              aadhaarVerified: true,
            },
          });
          setValidation({
            ...validation,
            aadhaarVerified: "",
          });

          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          let message = error.response.data.message;

          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
          setLoad(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // validating pan

  const handlePanChange = (e) => {
    const errors = { ...validation };
    const errorMessage = validateProperty(e.target);
    if (errorMessage) errors[e.target.name] = errorMessage;
    else errors[e.target.name] = "";
    delete errors["panNO"];
    delete errors["_PANVerified"];
    setData({
      ...data,
      kyc_details: {
        ...data.kyc_details,
        Pan_details: {
          ...data?.kyc_details?.Pan_details,
          pan: e.target.value.toUpperCase(),
        },
      },
    });
    setPanError(errors?.pan);

    setValidation({ ...errors });
  };

  const validatePan = async () => {
    if (data?.kyc_details?.Pan_details?.pan?.length === 0) {
      setPanError("Please Enter pan");
      return;
    } else {
      setPanError("");
    }
    if (panRegX.test(data?.kyc_details?.Pan_details?.pan)) {
      setPanError("");
      setLoad(true);
      try {
        await apiClient
          .post(PAN_VERIFICATION, {
            pan: data?.kyc_details?.Pan_details?.pan.trim(),
          })
          .then((response) => {
            setLoad(false);
            let message = response.data.message;

            if (response.data.status) {
              let responseData = response.data.response;
              // setPanUser(data);
              setData({
                ...data,
                kyc_details: {
                  ...data?.kyc_details,
                  Pan_details: responseData,
                  _PANVerified: true,
                },
              });

              setValidation({
                ...validation,

                _PANVerified: "",
              });

              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "success",
              });
            } else {
              // setPanUser({});
              console.log(message);
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "warning",
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            setLoad(false);
            console.log(error);
            let message = error.response.data.message;
            toast(message, {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });

            setLoad(false);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setPanError("Enter valid PAN Details");
      return;
    }
  };

  return (
    <div className="ms-2 mt-6">
      {load ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-lg text-[#45464E] font-medium">
            Add KYC Verification
          </h2>
          <div className="text-sm mt-10  min-[950px]:grid grid-cols-2 gap-y-4 gap-x-16 mx-0 min-[1250px]:mx-28">
            <div className="col-span-1  mb-5 min-[950px]:mb-0">
              <label className="block mb-2">Aadhar Number</label>
              <div className="inputWithButton">
                <input
                  disabled={data?.kyc_details?.aadhaarVerified}
                  type="text"
                  name="aadhar"
                  placeholder="Aadhar Number"
                  // onClick={(e) => handleInputChange(e)}
                  className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
                  value={data?.kyc_details?.Aadhar_Details?.aadhar || ""}
                  onChange={(e) => handleAadharChange(e)}
                />
                {aadharError !== "" && (
                  <span className="text-red-700">{aadharError}</span>
                )}
                {validation?.AadhararNo && (
                  <p className="text-red-800">{validation.AadhararNo}</p>
                )}

                {validation?.aadhaarVerified && (
                  <p className="text-red-800">{validation.aadhaarVerified}</p>
                )}
                <button
                  disabled={data?.kyc_details?.aadhaarVerified}
                  onClick={() => {
                    validateAddhar();
                  }}
                >
                  {verify ? "Get Otp" : "Verify"}
                </button>
              </div>
            </div>
            <div className="col-span-1  mb-5 min-[950px]:mb-0">
              {!data?.kyc_details?.aadhaarVerified && (
                <>
                  <label className="block mb-2">Enter OTP</label>
                  <div className="inputWithButton">
                    <input
                      type="text"
                      name="otp"
                      placeholder="Enter OTP"
                      className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
                      onChange={(e) => setOpt(e.target.value)}
                    />
                    {verify && (
                      <button
                        disabled={data?.kyc_details?.aadhaarVerified}
                        onClick={() => submitOtp()}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                  {verify && (
                    <p className="text-right">
                      <span className="text-primary font-bold">
                        Resend OTP{" "}
                      </span>{" "}
                      {verify && seconds > 0 ? "in " + seconds + " sec" : ""}
                    </p>
                  )}
                </>
              )}
            </div>
            {data?.kyc_details?.aadhaarVerified && (
              <>
                <div className="col-span-1 mb-5 min-[950px]:mb-0">
                  <span className="block mb-2">Name On Aadhar</span>
                  <div className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg py-2 pl-3">
                    {data?.kyc_details?.Aadhar_Details?.name}
                  </div>
                </div>
                <div className="col-span-1 mb-5 min-[950px]:mb-0">
                  <span className="block mb-2">Date Of Birth</span>
                  <div className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg py-2 pl-3">
                    {data?.kyc_details?.Aadhar_Details?.dob}
                  </div>
                </div>
              </>
            )}

            <div className="col-span-1 mb-5 min-[950px]:mb-0">
              <label className="block mb-2">PAN Card</label>
              <div className="inputWithButton">
                <input
                  disabled={data?.kyc_details?._PANVerified}
                  type="text"
                  name="pan"
                  placeholder="PAN Number"
                  className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg uppercase"
                  // value={pan}
                  value={data?.kyc_details?.Pan_details?.pan}
                  onChange={(e) => handlePanChange(e)}
                />
                {panError !== "" && (
                  <span className="text-red-700">{panError}</span>
                )}
                {validation?.panNO && (
                  <p className="text-red-800">{validation.panNO}</p>
                )}
                {validation?._PANVerified && (
                  <p className="text-red-800">{validation._PANVerified}</p>
                )}
                <button
                  disabled={data?.kyc_details?._PANVerified}
                  onClick={() => validatePan()}
                >
                  Verify
                </button>
              </div>
            </div>
            <div className="col-span-1 mb-5 min-[950px]:mb-0"></div>

            {data?.kyc_details?._PANVerified && (
              <div className="col-span-1">
                <span className="block mb-2">Name On PAN Card</span>
                <div className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg py-2 pl-3">
                  {data?.kyc_details?.Pan_details?.registered_name}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default KYCVerification;
