import React from "react";
import { RxCross2 } from "react-icons/rx";
import TransactionPopupField from "./TransactionPopupField";

function TransactionPopUp({ row, setIsPopupOpen }) {
  const customerData = row?.Customer_id?.Customer_data;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-75">
      <div className=" p-4 rounded shadow-lg w-[500px] bg-neutral-200 rounded">
        <div className="flex justify-end mb-2">
          <button onClick={() => setIsPopupOpen(false)}>
            {<RxCross2 className="text-primary hover:text-blue-700 w-5 h-5" />}
          </button>
        </div>

        <TransactionPopupField title="Airpay Id" data={row.airpay_id} />
        <TransactionPopupField
          title="Name"
          data={
            customerData?.FirstName && customerData?.LastName
              ? `${customerData?.FirstName} ${customerData?.LastName}`
              : "Not available"
          }
        />
        <TransactionPopupField
          title="Email"
          data={customerData?.Email ? customerData?.Email : "Not available"}
        />
        <TransactionPopupField
          title="Mobile"
          data={
            row?.Customer_id?.mobile
              ? row?.Customer_id?.mobile
              : "Not available"
          }
        />
        <TransactionPopupField
          title="Address"
          data={customerData?.Address ? customerData?.Address : "Not available"}
        />
        <TransactionPopupField
          title="Customer Id"
          data={
            customerData?.Customer_id
              ? customerData?.Customer_id
              : "Not available"
          }
        />
        <TransactionPopupField
          title="Pincode"
          data={customerData?.Pincode ? customerData?.Pincode : "Not available"}
        />
        <TransactionPopupField
          title="State"
          data={customerData?.State ? customerData?.State : "Not available"}
        />
        <TransactionPopupField
          title="City"
          data={customerData?.City ? customerData?.City : "Not available"}
        />
      </div>
    </div>
  );
}

export default TransactionPopUp;
