import React, { useState } from "react";
import { toast } from "react-toastify";
import greenCheckbox from "../../assets/svg/GREENcheckbox.svg";
import apiClient from "../../service/apiClient";
import { BANK_VERIFICATION } from "../../service/apiConstants";
import Loader from "../Loader/Loader";
import _ from "lodash";

const BankInfo = ({ data, setData, validation, setValidation }) => {
  const [load, setLoad] = useState(false);
  // const [accountNumber, setAccountNumber] = useState("");
  // const [ifsc, setIfsc] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [ifscError, setIfscError] = useState("");
  // const [bankUser, setBankUser] = useState({});
  const bankIfscRegX = /^ *[A-Z]{4}0[A-Z0-9]{6} *$/;

  const validateProperty = ({ name, value }) => {
    console.log(name, value);

    if (name === "accountNo") {
      if (!value && _.isEmpty(value)) {
        return "AccountNo name is required";
      } else if (!(value.trim().length >= 9 && value.trim().length <= 18)) {
        return "Invalid account number";
      }
    }

    if (name === "ifsc") {
      if (!value && _.isEmpty(value)) {
        return "IFSC code is required";
      } else if (!bankIfscRegX.test(value.trim())) {
        return "Invalid IFSC number.";
      }
    }
  };

  const handleInputChange = (e) => {
    const errors = { ...validation };
    const errorMessage = validateProperty(e.target);
    if (errorMessage) errors[e.target.name] = errorMessage;
    else errors[e.target.name] = "";
    delete errors["bank_verified"];
    // setData({
    //   ...data,
    //   bank_details: {
    //     ...data?.bank_details,
    //     account: {
    //       ...data?.bank_details?.account,
    //       [e.target.name]: e.target.value,
    //     },
    //   },
    // });
    setData({
      ...data,
      bank_details: {
        ...data?.bank_details,
        account: {
          ...data?.bank_details?.account,
          [e.target.name]: e.target.value,
        },
      },
    });

    if (e.target.name === "accountNo") {
      setAccountNumberError(errors.accountNo);
    } else {
      setIfscError(errors.ifsc);
    }

    setValidation({ ...errors });
  };

  const validateBankDetaile = async () => {
    // setBankUser({});

    if (data?.bank_details?.account?.accountNo?.length === 0) {
      setAccountNumberError("AccountNo name is required");
      return;
    } else if (
      !(
        data?.bank_details?.account?.accountNo?.trim().length >= 9 &&
        data?.bank_details?.account?.accountNo?.trim().length <= 18
      )
    ) {
      setAccountNumberError("Enter valid account number");
      return;
    } else {
      setAccountNumberError("");
    }

    if (data?.bank_details?.account?.ifsc?.length === 0) {
      setIfscError("IFSC code is required");
      return;
    } else {
      setIfscError("");
    }

    if (bankIfscRegX.test(data?.bank_details?.account?.ifsc)) {
      setAccountNumberError("");
      setIfscError("");
      setLoad(true);
      try {
        await apiClient
          .post(BANK_VERIFICATION, {
            fullName: data?.Customer_data?.FullName.trim(),
            mobile: data?.mobile.trim(),
            bankAccount: data?.bank_details?.account?.accountNo.trim(),
            ifsc: data?.bank_details?.account?.ifsc.trim(),
          })
          .then((response) => {
            console.log("Bank verify response==>", response);

            let status = response?.data?.response?.data?.status;
            let message = response?.data?.response?.message;

            if (status === "SUCCESS") {
              setData({
                ...data,
                bank_details: {
                  ...data?.bank_details,
                  account: response.data.response?.account,
                  bank_verified: true,
                },
              });

              setValidation({
                ...validation,
                bank_verified: "",
              });
              // setBankUser(response.data.response.data);
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "success",
              });
            } else {
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "warning",
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            console.log(error);
            setLoad(false);
            toast("Server Error wait", {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
          });
      } catch (error) {
        setLoad(false);
        console.log(error);
      }
    } else {
      setIfscError("Enter valid IFSC");
    }
  };

  return (
    // <div>
    //   <h2>Step 2</h2>
    //   <input type="text" onChange={handleInputChange} />
    // </div>
    <div className="ms-2 mt-6">
      {load ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-lg text-[#45464E] font-medium">
            Add Bank information
          </h2>
          <div className="text-sm mt-10  min-[950px]:grid grid-cols-2 gap-y-4 gap-x-16 mx-0 min-[1250px]:mx-28">
            <div className="col-span-1 mb-5 min-[950px]:mb-0">
              <label className="block mb-2">Account Number</label>
              <div className="inputWithButton">
                <input
                  disabled={data?.bank_details?.bank_verified}
                  type="text"
                  name="accountNo"
                  placeholder="Account Number"
                  value={data?.bank_details?.account?.accountNo}
                  className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
                  // onChange={(e) => setAccountNumber(e.target.value)}
                  onChange={(e) => handleInputChange(e)}
                />
                {accountNumberError !== "" && (
                  <span className="text-red-700">{accountNumberError}</span>
                )}
                {/* {validation?.accountNo && (
                  <p className="text-red-800">{validation.accountNo}</p>
                )} */}
              </div>
            </div>
            <div className="col-span-1 mb-5 min-[950px]:mb-0">
              <label className="block mb-2">IFSC Code</label>
              <div className="inputWithButton">
                <input
                  disabled={data?.bank_details?.bank_verified}
                  type="text"
                  name="ifsc"
                  placeholder="IFSC Code"
                  value={data?.bank_details?.account?.ifsc}
                  className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
                  onChange={(e) => handleInputChange(e)}
                />
                {ifscError !== "" && (
                  <span className="text-red-700">{ifscError}</span>
                )}
                {/* {validation?.ifsc && (
                  <p className="text-red-800">{validation.ifsc}</p>
                )} */}
                {validation?.bank_verified && (
                  <p className="text-red-800">{validation.bank_verified}</p>
                )}
                <button
                  onClick={() => validateBankDetaile()}
                  disabled={data?.bank_details?.bank_verified}
                >
                  Verify
                </button>

                {data?.bank_details?.bank_verified && (
                  <div className="flex mt-2 content-center h-8">
                    <div>
                      <span className="mr-2 align-middle">
                        {data?.bank_details?.account?.bankName}
                      </span>
                    </div>
                    <div>
                      <img src={greenCheckbox} alt="" className="" />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {data?.bank_details?.bank_verified && (
              <>
                <div className="col-span-1 mb-5 min-[950px]:mb-0">
                  <span className="block mb-2">Name</span>
                  <div className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg py-2 pl-3">
                    {data?.bank_details?.account?.bankName}
                  </div>
                  <div className="flex mt-2 content-center h-8">
                    <div>
                      <span className="mr-2 align-middle">
                        Bank Verified Name
                      </span>
                    </div>
                    <div>
                      <img src={greenCheckbox} alt="" className="" />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <span className="block mb-2">Branch</span>
                  <div className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg py-2 pl-3">
                    {data?.bank_details?.account?.branch || "Not available"}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BankInfo;
