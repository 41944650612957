import React from "react";
import Profile from "./Profile";
import Notification from "../model/Notification";

function UserProfile(props) {
  return (
    <div className="flex  item-center">
      <Notification />
      <Profile />
    </div>
  );
}

export default UserProfile;
