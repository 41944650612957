import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import image from "../../assets/svg/Image.svg";
import uploadImage from "../../assets/svg/cloud_upload.svg";
import cross from "../../assets/svg/cross.svg";

function DocumentUpload({
  item,
  index,
  Doc_type,
  removeDoc,
  handleFileChange,
  uploadDocsError,
}) {
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

  const [file, setFile] = useState();
  const [docImage, setDocImage] = useState(null);

  React.useEffect(() => {
    if (item?.file) {
      setFile(item?.file);
      const reader = new FileReader();

      reader.onload = (event) => {
        setDocImage(event.target.result);
      };

      reader.readAsDataURL(item?.file);
    }
  }, [item]);
  const handleChange = (file) => {
    handleFileChange(Doc_type, file);
    // setFile(file);
    // if (file) {
    //   const reader = new FileReader();

    //   reader.onload = (event) => {
    //     setDocImage(event.target.result);
    //   };

    //   reader.readAsDataURL(file);
    // }
  };
  const handleRemoveFile = (Doc_type) => {
    console.log(file);
    // setFile(null);
    removeDoc(Doc_type);
  };
  return (
    <div key={index}>
      <div className="flex justify-between w-11/12	min-[950px]:w-10/12 mx-auto mb-[5px]">
        <div className="w-5/12">
          <span className="text-sm text-gray-500 ">
            {item.Doc_type} Document
          </span>
        </div>
        <div className="w-5/12 flex justify-center"></div>
      </div>
      <div className="flex justify-between w-11/12	min-[950px]:w-10/12 mx-auto mb-2">
        <div className="w-5/12">
          <span className="text-sm text-gray-500 ">{item.Doc_type} image</span>
        </div>
        <div className="w-5/12 flex justify-center"></div>
      </div>
      <div className="flex justify-between w-11/12	min-[950px]:w-10/12 mx-auto mb-6">
        <div className="w-5/12">
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            children={
              docImage ? (
                <img
                  src={docImage}
                  alt="Selected Image"
                  style={{ maxWidth: "100%" }}
                />
              ) : (
                <>
                  <div className="bg-[#EFF1F999] py-3 flex flex-col items-center rounded-lg cursor-pointer">
                    <img src={image} alt="" className="w-20 h-20" />
                    <div className="flex my-2 ">
                      <img src={uploadImage} alt="" />
                      <span className="text-md text-primary">Upload Image</span>
                    </div>
                    <p className="text-xs text-center font-normal">
                      Upload {item.Doc_type} image.
                    </p>
                    <p className="text-xs text-center font-normal">
                      File Format <span className="font-bold">jpeg, png</span>{" "}
                      Recommened Size <span className="font-bold">1mb</span>
                    </p>
                  </div>
                </>
              )
            }
          />
          {uploadDocsError && (
            <p className="text-red-500 mt-1 text-sm">
              {uploadDocsError.message}
            </p>
          )}
        </div>

        <div className="w-6/12">
          <div className="mb-1">
            <span className="text-sm text-gray-500 mb-2">
              {item.Doc_type} image
            </span>
          </div>
          <div className="flex justify-between border px-1  ">
            <p className="text-sm">
              {file ? `${file.name}` : "no files uploaded yet"}
            </p>
            <button onClick={() => handleRemoveFile(Doc_type)}>
              <img src={cross} alt="" className="max-w-fit" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUpload;
