import React, { useContext, useEffect, useMemo, useState } from "react";
import { auth, logout } from "../Firebase";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { BASE_URL, GET_USER_ROLE } from "../service/apiConstants";
import { toast } from "react-toastify";

const AuthContext = React.createContext();

export const AuthContextProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const navigate = useNavigate();

  const [loading, setLoding] = useState(true);
  function getAccessToken() {
    return user?.getIdToken(true);
  }

  const getUserRoleFunc = async (user) => {
    try {
      const result = await fetch(BASE_URL + GET_USER_ROLE, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }).then(async (result) => {
        console.log(result);
        return await result.json();
      });
      console.log(result);

      // Check if user is not authorized
      if (result.status !== true && result.code === 400) {
        logoutCurrentUser();
        toast("You are not authorized user", {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      }
      if (result.status !== true) {
        setLoding(false);
        logoutCurrentUser();
        // throw new Error("Failed to login");
      }

      // Update user object with the role from the response
      setUser({ ...user, role: result.response.role });
    } catch (error) {
      console.log(error);
      setLoding(false);
      logoutCurrentUser();
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      let userSessionTimeout = null;

      // Check if user is logged out or session has expired
      if (_.isNull(user) && userSessionTimeout) {
        clearTimeout(userSessionTimeout);
        userSessionTimeout = null;
      } else if (!_.isNull(user)) {
        // Get user's ID token result to calculate session expiration time
        await user.getIdTokenResult().then((idTokenResult) => {
          const authTime = idTokenResult.claims.auth_time * 1000;
          const sessionDurationInMilliseconds = 2 * 60 * 60 * 1000; // 120 min
          // const sessionDurationInMilliseconds = 60 * 1000; // 1 min
          const expirationInMilliseconds =
            sessionDurationInMilliseconds - (Date.now() - authTime);
          // Set a timeout to automatically log out the user when the session expires
          if (expirationInMilliseconds <= 0) {
            clearTimeout(userSessionTimeout);
            userSessionTimeout = null;
            logoutCurrentUser(false);
          } else {
            userSessionTimeout = setTimeout(() => {
              logoutCurrentUser(false);
            }, expirationInMilliseconds);
            // Perform actions when user logs in
            loginCurrentUser(user);
          }
        });
      } else {
        logoutCurrentUser(false);
      }
      setLoding(false);
    });

    // Unsubscribe from auth state changes when component unmounts
    return () => unsubscribe();
  }, []);

  const loginCurrentUser = async (data) => {
    const userdata = getUserRoleFunc(data);

    // setUser(data);
    // navigate("/dashboard/rent-payouts");
  };

  const logoutCurrentUser = () => {
    logout();
    setUser(false);
    navigate("/login", { replace: true }); //Redirecting to login page on logout
  };

  const value = useMemo(
    () => ({
      user,
      logout,
      loginCurrentUser,
      logoutCurrentUser,
      loading,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
