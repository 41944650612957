// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  /* margin: 24px; */
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 25%;
  left: 0;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0deg);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
`, "",{"version":3,"sources":["webpack://./src/components/HamburgerMenuButton/HamburgerButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;;EAGE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yDAAyD;AAC3D","sourcesContent":[".hamburger {\n  cursor: pointer;\n  width: 24px;\n  height: 24px;\n  /* margin: 24px; */\n  transition: all 0.25s;\n  position: relative;\n}\n\n.hamburger-top,\n.hamburger-middle,\n.hamburger-bottom {\n  position: absolute;\n  top: 25%;\n  left: 0;\n  width: 24px;\n  height: 2px;\n  background: #000;\n  transform: rotate(0deg);\n  transition: all 0.5s;\n}\n\n.hamburger-middle {\n  transform: translateY(7px);\n}\n\n.hamburger-bottom {\n  transform: translateY(14px);\n}\n\n.open {\n  transform: rotate(90deg);\n  transform: translateY(0px);\n}\n\n.open .hamburger-top {\n  transform: rotate(45deg) translateY(6px) translate(6px);\n}\n\n.open .hamburger-middle {\n  display: none;\n}\n\n.open .hamburger-bottom {\n  transform: rotate(-45deg) translateY(6px) translate(-6px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
