import React from "react";
import SearchBox from "../forms/SearchBox";
import UserProfile from "./UserProfile";
import MobileMenu from "../MobileMenu";

function Navbar(props) {
  return (
    <div className="px-4 py-4 bg-white justify-between flex sm:justify-end gap-2 item-center border-b-2">
      <MobileMenu />
      {/* <SearchBox /> */}
      <UserProfile />
    </div>
  );
}

export default Navbar;
