import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../components/Loader/Loader";
import _ from "lodash";
import apiClient from "../service/apiClient";
import { GET_REPORT } from "../service/apiConstants";
import { checkIsMobile, getDateString } from "../service/helper";
import PageTitle from "../components/model/PageTitle";

import { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "../service/shouldForwardProp";
import UsersSummary from "../components/Users/UsersSummary";
import downloadIcon from "../assets/svg/download.svg";
import TotalUser from "../assets/svg/UserPageSvg/TotalUser.svg";
import Pagination from "../components/Pagination/Pagination";
import { downloadCSVOfReport } from "../service/usersApis";
import Datepicker from "react-tailwindcss-datepicker";
import html2pdf from "html2pdf.js";
import { utils, writeFile } from "xlsx";

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="">
      <div className="relative flex w-full items-stretch">
        <input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search"
          value={filterText}
          onChange={onFilter}
          className="relative m-0  block  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-black-100  dark:focus:border-primary"
        />

        <button
          className="relative z-[2] flex items-center rounded-r bg-primary hover:bg-blue-700 px-2 py-2 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={onClear}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </>
);

//export components
const Export = ({ onExport }) => (
  <button
    className="bg-primary text-sm sm:text-base text-white hover:bg-blue-700 text-white font-bold items-center px-2 sm:py-2 sm:px-4 rounded font-normal h-[35px] sm:h-[42px] flex gap-1 sm:gap-2"
    onClick={(e) => onExport(e.target.value)}
  >
    <img src={downloadIcon} alt="" className=" w-4 h-4 sm:w-6 sm:h-6" />
    <span>Export</span>
  </button>
);

// custom style for data table

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {},
  },
};

const Reports = () => {
  // use for getting data from server
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const [filteredItems, setFilteredItems] = useState([]);
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  });

  const getUsers = async () => {
    setLoad(true);
    await apiClient
      .get(GET_REPORT)
      .then((response) => {
        setData(response.data.response.reportArray.reverse());
        console.log(response);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  React.useEffect(() => {
    let mapData = data.map((item, index) => {
      return { ...item, ["Index"]: data.length - index };
    });
    if (filterText !== "") {
      mapData = mapData.filter((item) => {
        return item.title?.toLowerCase().includes(filterText.toLowerCase());
      });
    }
    if (!_.isEmpty(dateRange.startDate) && !_.isEmpty(dateRange.endDate)) {
      const startDate = new Date(dateRange.startDate);
      startDate.setUTCHours(0, 0, 0, 0);
      const endDate = new Date(dateRange.endDate);
      endDate.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          startDate.getTime() <= itemDate.getTime() &&
          itemDate.getTime() <= endDate.getTime()
        );
      });
    }
    setFilteredItems(mapData);
    // filter through date
  }, [data, filterText, dateRange]);

  //sort function for date column

  const dateSortFun = (rowA, rowB) => {
    const dateA = new Date(rowA.siginlast);
    const dateB = new Date(rowB.siginlast);
    dateA.toISOString();
    dateB.toISOString();

    if (dateA > dateB) {
      return 1;
    }
    if (dateB > dateA) {
      return -1;
    }

    return 0;
  };
  // setting columns for table view

  const columns = [
    {
      name: " SL",
      selector: (row, index) => index + 1,
      grow: -1,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      // width: "200px",
    },
    {
      name: "Total Revenue",
      selector: (row) => {
        return <div>₹{row.TotalRevenue}</div>;
        // width: "200px",
      },
    },

    {
      name: "PDF",
      button: true,
      selector: (row) => {
        return (
          <div>
            <button
              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-2 me-1 rounded"
              title={"Follow Up"}
              onClick={() => downloadPdf(row)}
            >
              <img
                src={downloadIcon}
                alt=""
                className=" w-4 h-4 sm:w-6 sm:h-6"
              />
            </button>
          </div>
        );
      },
    },

    {
      name: "EXCEL",
      button: true,
      selector: (row) => {
        return (
          <div>
            <button
              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-2 me-1 rounded"
              title={"Follow Up"}
              onClick={() => downloadExcel(row)}
            >
              <img
                src={downloadIcon}
                alt=""
                className=" w-4 h-4 sm:w-6 sm:h-6"
              />
            </button>
          </div>
        );
      },
    },
    {
      name: "Date",
      selector: (row) => getDateString(row.createdAt, true),
      sortable: true,
      sortFunction: dateSortFun,
      center: true,
    },
  ];

  //download pdf

  const downloadPdf = (row) => {
    console.log(row.ReportList);

    //creating html file for report
    const element = document.createElement("div");
    element.innerHTML = "";
    let serialNumber = 1; // Variable to keep track of the serial number
    let elementTwo = `<h1 style="text-align: center; font-size:20px; margin-top: 20px; ">${
      row?.title
    }</h1>
      <table>
        <tr>
          <th>SL</th>
          <th>Created_At</th>
          <th>State</th>
          <th>Total Revenue</th>
          <th>Amount Disbursed</th>
        </tr>
        ${row.ReportList.map(
          (item) =>
            "<tr>" +
            "<td>" +
            serialNumber++ +
            "</td>" +
            "<td>" +
            getDateString(row.createdAt) +
            "</td>" +
            "<td>" +
            item.State +
            "</td>" +
            "<td>" +
            item.totalConvieFee +
            "</td>" +
            "<td>" +
            item.totalAmount +
            "</td>" +
            "</tr>"
        ).join("")}
      </table>

  `;
    element.innerHTML = ` <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Report</title>
    <style>
      table,
      th,
      td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px;
        text-align:center;
        vertical-align: middle;
      }

      table {
        margin: auto;
        margin-top :20px;
        width: 90%;
      }
    </style>
  </head> <body> ${elementTwo} <p style= "text-align:right; margin-right: 40px; font-weight: bold;">Total Revenue:- <span style = "font-weight: normal;"> ${row.TotalRevenue}</span></p> <br/> </body>`;

    html2pdf()
      .from(element)
      .set({
        filename: "transactionInvoice.pdf",
        image: { type: "jpeg", quality: 0.95 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 300,
          letterRendering: true,
        },
      })
      .toPdf()
      .get("pdf")
      .save();
  };

  //download excel

  const downloadExcel = (row) => {
    let newArray;
    let index = 1;
    newArray = row.ReportList.map((item) => {
      return {
        // Transaction_ID: item?.transfer_id,
        // Mobile: item?.ben_mobile,
        // Email: item?.ben_email,
        // Transaction_Date: item?.created_At,
        // Account_No: item?.benificary_details?.acc_no,
        // IFSC: item?.benificary_details?.IFSC_code,
        // Amount: item?.amount,
        // Type: item?.transfer_type,
        // Status: item?.object_transfer?.metaData.status,
        SL: index++,
        Created_At: getDateString(row?.createdAt),
        State: item?.State,
        Total_Revenue: item?.totalConvieFee,
        Amount_Disbursed: item?.totalAmount,
      };
    });

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(newArray);
    utils.book_append_sheet(wb, ws, "export");
    writeFile(wb, "export.xlsx");
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const handleValueChange = (newValue) => {
      setDateRange(newValue);
    };

    return (
      <div className="w-full flex flex-col xl:flex-row justify-between items-left mb-5 p-0">
        <h3 className="mb-2">List</h3>
        <div className=" flex flex-col lg:flex-row item-stratch flex-wrap gap-3 sm:gap-4 md:gap-5 items-center md:items-start">
          <div className="w-[265px]">
            <Datepicker
              placeholder={"Select Date"}
              value={dateRange}
              onChange={handleValueChange}
              useRange={false}
              maxDate={new Date()}
              primaryColor={"blue"}
              inputClassName="w-[265px] px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base h-[35px] sm:h-[42px] rounded-md focus:ring-0 font-normal bg-white-100 dark:placeholder:text-black-100"
              toggleClassName="absolute bg-primary hover:bg-blue-700 rounded-r-md text-white right-0 h-full px-2 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
            />
          </div>
          <div>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </div>
          <div>
            <Export
              onExport={() => downloadCSVOfReport(filteredItems, selectedRows)}
            />
          </div>
        </div>
      </div>
    );
  }, [
    filterText,
    resetPaginationToggle,
    filteredItems,
    dateRange,
    selectedRows,
  ]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  return (
    <main className="p-4 w-full">
      <PageTitle title="Reports" />
      {load ? (
        <Loader />
      ) : (
        <div className="user-table">
          <div className="flex justify-between gap-6 mb-6">
            <UsersSummary
              count={data.length}
              title={"Total Reports"}
              icon={TotalUser}
            />
          </div>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationComponent={Pagination}
              progressPending={load}
              progressComponent={<Loader />}
              fixedHeader
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              dense
            />
          </StyleSheetManager>
        </div>
      )}
    </main>
  );
};

export default Reports;
