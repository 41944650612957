import React from "react";
import _ from "lodash";

const AgentCommission = ({ data, setData, validation, setValidation }) => {
  const validateProperty = ({ name, value }) => {
    console.log(name, value);

    if (name === "BasePersentage") {
      if (!value && _.isEmpty(value)) {
        return "Enter Base Commission";
      } else if (parseFloat(value) > 5.0) {
        return "Base Commission should between 0 to 5 percentage";
      }
    }

    if (name === "markupPercentage") {
      if (!value && _.isEmpty(value)) {
        return "Enter markup Commission";
      } else if (parseFloat(value) > 0.7) {
        return "Enter MarkUp Commission as per limit prescribed";
      }
    }
  };

  const handleInputChange = (e) => {
    const errors = { ...validation };
    const errorMessage = validateProperty(e.target);
    if (errorMessage) errors[e.target.name] = errorMessage;
    else errors[e.target.name] = "";

    setData({
      ...data,
      Commission: {
        ...data?.Commission,
        [e.target.name]: e.target.value,
      },
    });

    setValidation({ ...validation, ...errors });
  };

  return (
    <div className="ms-2 mt-6">
      <h2 className="text-lg text-[#45464E] font-medium">
        Add Agent Commissions
      </h2>
      <div className="text-sm mt-10  min-[950px]:grid grid-cols-2 gap-y-4 gap-x-16 mx-0 min-[1250px]:mx-28">
        <div className="col-span-1 mb-5 min-[950px]:mb-0">
          <label className="block mb-2">Base commission</label>
          <div className="inputWithButton">
            <input
              type="text"
              name="BasePersentage"
              placeholder="Add Base percentage"
              value={data?.Commission?.BasePersentage}
              onChange={(e) => handleInputChange(e)}
              className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg "
            />
            {validation?.BasePersentage && (
              <p className="text-red-800">{validation.BasePersentage}</p>
            )}
          </div>
        </div>
        <div className="col-span-1">
          <label className="block mb-2">Markup  Commission</label>
          <div className="inputWithButton">
            <input
              type="text"
              name="markupPercentage"
              placeholder="Add Markup percentage"
              value={data?.Commission?.markupPercentage}
              onChange={(e) => handleInputChange(e)}
              className="text-base block border-0 bg-[#EFF1F999] w-full rounded-lg"
            />
            {validation?.markupPercentage && (
              <p className="text-red-800">{validation.markupPercentage}</p>
            )}
            <p className="text-[#FFC300] my-1">Note: Max limit is 0.7%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentCommission;
