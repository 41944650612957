import _ from "lodash";
import React, { useState } from "react";
import AgentCommission from "../components/AgentOnboarding/AgentCommission";
import Documents from "../components/AgentOnboarding/Document";
import KYCVerification from "../components/AgentOnboarding/KycVerification";
import PersonalInfo from "../components/AgentOnboarding/PersonalInfo";
import Loader from "../components/Loader/Loader";
import apiClient, { post } from "../service/apiClient";
import { ADD_AGENT, SUBMIT_AGENT, UPDATE_AGENT } from "../service/apiConstants";
import BankInfo from "../components/AgentOnboarding/BankInfo";
import axios from "axios";
import validate from "../schemas/AgentValidation";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
const initialErrors = {
  mobile: "",
  Email: "",
  FullName: "",
  Business_Name: "",
  Address: "",
  Pincode: "",
  AadhararNo: "",
  aadhaarVerified: "",
  panNO: "",
  _PANVerified: "",
  accountNo: "",
  ifsc: "",
  bank_verified: "",
  markupPercentage: "",
  BasePersentage: "",
  Documents: "",
};

const initialDocsErrors = {
  addDocsError: "",
  uploadDocsErrors: [],
};

const AddAgent = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [step, setStep] = React.useState(1);
  const [formData, setFormData] = React.useState({
    mobile: "",
    Email: "",
    Customer_data: {
      FullName: "",
      Business_Name: "",
      Address: "",
      Pincode: "",
      State: "",
      City: "",
      photoURL: "",
    },
    kyc_details: {
      Aadhar_Details: {},
      Pan_details: {},
      aadhaarVerified: false,
      _PANVerified: false,
    },
    bank_details: {
      account: {},
      bank_verified: false,
    },
    Commission: {
      markupPercentage: 0.7,
      BasePersentage: 1.8,
    },
    Documents: [],
    kyc_status: false,
    emailVerified: false,
    created_At: Date.now,
    referalCode: "",
    referalCommission: 0.3,
  });

  const [validation, setValidation] = useState(initialErrors);

  const [submitValidation, setSubmitValidation] = useState(initialDocsErrors);

  const validateDocumentArray = (documents) => {
    let newErrors = {};
    let flag = true;
    let uploadDocsErrors = [];

    if (!Array.isArray(documents) || documents.length < 2) {
      console.log("documents");
      newErrors = {
        ...newErrors,
        addDocsError: "minimum 2 documents required",
      };
      flag = false;
    }

    for (let i = 0; i < documents.length; i++) {
      const entry = documents[i];
      if (!entry || !entry.Doc_type || !entry.file) {
        uploadDocsErrors.push({
          index: i,
          message: "Upload this field document",
        });
        flag = false;
      }
    }
    if (flag === false) {
      setSubmitValidation({
        ...newErrors,
        uploadDocsErrors: uploadDocsErrors,
      });
      return false;
    }
    setSubmitValidation(initialDocsErrors);
    return true;
  };

  function cloneAndTrimData(originalObject) {
    const clonedObject = {};

    for (const key in originalObject) {
      if (key === "Customer_data") {
        // Trim spaces from Customer_data fields
        clonedObject[key] = {};
        for (const nestedKey in originalObject[key]) {
          if (typeof originalObject[key][nestedKey] === "string") {
            clonedObject[key][nestedKey] =
              originalObject[key][nestedKey].trim();
          } else {
            clonedObject[key][nestedKey] = originalObject[key][nestedKey];
          }
        }
      } else if (key === "mobile" || key === "Email") {
        // Trim spaces from mobile and Email
        clonedObject[key] = originalObject[key].trim();
      } else if (key === "Documents") {
        // Copy Documents without modification
        clonedObject[key] = originalObject[key];
      } else if (
        typeof originalObject[key] === "object" &&
        originalObject[key] !== null
      ) {
        // Recursively handle nested objects
        clonedObject[key] = cloneAndTrimData(originalObject[key]);
      } else {
        // Copy other fields without modification
        clonedObject[key] = originalObject[key];
      }
    }

    return clonedObject;
  }

  const handleNextStep = async () => {
    const validationErrors = validate(step, formData);
    setValidation(validationErrors);
    const isValid = Object.keys(validationErrors).length === 0;
    console.log("formData==>", formData);
    console.log("Error==>", validationErrors, isValid);

    const trimData = cloneAndTrimData(formData);
    console.log("trimdata==>", trimData);

    if (isValid) {
      if (step === 1 && formData?.Customer_data?.Pincode) {
        const pincode = formData?.Customer_data?.Pincode;
        setLoad(true);
        await fetch(`https://api.postalpincode.in/pincode/${pincode}`)
          .then((response) => response.json())
          .then((data) => {
            setLoad(false);
            setFormData({
              ...formData,
              Customer_data: {
                ...formData.Customer_data,
                State: data[0]?.PostOffice[0]?.State,
                City: data[0]?.PostOffice[0]?.District,
              },
            });
          })
          .catch((error) => {
            setLoad(false);
            console.error("Error:", error);
            // Handle errors here
          });
      }
      setLoad(true);
      const apiUrl =
        step === 1 && _.isEmpty(trimData._id) ? ADD_AGENT : UPDATE_AGENT;
      await apiClient
        .post(apiUrl, trimData)
        .then((response) => {
          console.log("response", response);
          // toast("Kyc Status Updated", {
          //   theme: "dark",
          //   hideProgressBar: true,
          //   type: "success",
          // });
          setLoad(false);
        })
        .catch((error) => {
          console.log("error");
          setLoad(false);
        });
      setStep(step + 1);
    }
  };
  const handlePreviousStep = () => {
    if (step === 0) {
      return;
    }
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    console.log("final form data====>", formData);

    const trimData = cloneAndTrimData(formData);
    console.log("trimdata==>", trimData);

    const isValid = validateDocumentArray(trimData?.Documents);
    if (isValid) {
      const formDataNew = new FormData();
      Object.keys(trimData).map((key) => {
        if (key !== "Documents") {
          formDataNew.append(
            key,
            typeof trimData[key] != "string"
              ? JSON.stringify(trimData[key])
              : trimData[key]
          );
        }
      });
      if (trimData?.Documents?.length > 0) {
        trimData?.Documents.map((f) => {
          formDataNew.append("Documents[]", f.file);
        });
      }
      setLoad(true);
      await apiClient
        .post(SUBMIT_AGENT, formDataNew, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log("submit-response", response);
          if (
            String(response?.data?.code) === "201" &&
            response.data.status === true
          ) {
            toast(
              response?.data?.message
                ? response?.data?.message
                : "Agent Saved Successfully",
              {
                theme: "dark",
                hideProgressBar: true,
                type: "success",
              }
            );

            navigate("/dashboard/agentsmanagement");
          } else {
            toast(
              response?.data?.message
                ? response?.data?.message
                : "Something Wrong",
              {
                theme: "dark",
                hideProgressBar: true,
                type: "error",
              }
            );
          }
          setLoad(false);
        })
        .catch((error) => {
          console.log("error");
          toast(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Something Wrong",
            {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            }
          );
          setLoad(false);
        });
    }
  };
  return (
    <div className="m-0 min[440px]:m-5">
      {load ? (
        <Loader />
      ) : (
        <>
          <div className="scrollForForm overflow-auto overflow-y-hidden">
            <ul className="flex text-base font-semibold text-gray-400 gap-8 border-b-2 px-2">
              {[
                { title: "Personal Information", id: 1 },
                { title: "KYC Information", id: 2 },
                { title: "Bank Information", id: 3 },
                { title: "Agent Commissions", id: 4 },
                { title: "Documents", id: 5 },
              ].map((item, key) => {
                return (
                  <li
                    key={"menu-" + key}
                    className={`${
                      Number(step) === Number(item.id) &&
                      "text-primary content_border agent-menu-item"
                    } pb-4 mx-3 whitespace-nowrap	`}
                  >
                    {item.id} {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
          {step === 1 && (
            <PersonalInfo
              data={formData}
              setData={(data) => setFormData({ ...formData, ...data })}
              validation={validation}
              setValidation={(err) => setValidation({ ...validation, ...err })}
            />
          )}
          {step === 2 && (
            <KYCVerification
              data={formData}
              setData={(data) => setFormData({ ...formData, ...data })}
              validation={validation}
              setValidation={(err) => setValidation({ ...err })}
            />
          )}
          {step === 3 && (
            <BankInfo
              data={formData}
              setData={(data) => setFormData({ ...formData, ...data })}
              validation={validation}
              setValidation={(err) => setValidation({ ...err })}
            />
          )}
          {step === 4 && (
            <AgentCommission
              data={formData}
              setData={(data) => setFormData({ ...formData, ...data })}
              validation={validation}
              setValidation={(err) => setValidation({ ...err })}
            />
          )}
          {step === 5 && (
            <Documents
              data={formData}
              setData={(data) => {
                setFormData({ ...formData, ...data });

                setSubmitValidation({
                  ...submitValidation,
                  uploadDocsErrors: [],
                  addDocsError: "",
                });
              }}
              submitValidation={submitValidation}
            />
          )}
          <div className="my-12 sm:my-24 text-primary flex justify-center sm:justify-end">
            {step === 1 && (
              <NavLink
                to="/dashboard/agentsmanagement"
                className="border border-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-4 min-[400px]:px-8 py-1 mr-8"
              >
                <span className="mr-2">&#8592;</span> Back
              </NavLink>
            )}
            {step > 1 && (
              <button
                className="border border-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-4 min-[400px]:px-8 py-1 mr-8"
                onClick={handlePreviousStep}
              >
                <span className="mr-2">&#8592;</span> Back
              </button>
            )}
            {step < 5 ? (
              <button
                className="border border-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-4 min-[400px]:px-8 py-1"
                onClick={handleNextStep}
              >
                Next <span className="mr-2">&#8594;</span>
              </button>
            ) : (
              // <button onClick={handleSubmit}>Submit</button>
              <button
                className="border border-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-2 min-[400px]:px-6 py-1"
                onClick={handleSubmit}
              >
                Submit <span className="ml-2">&#8594;</span>
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AddAgent;
