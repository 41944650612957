import React from "react";
import { BiEditAlt, BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import { EDIT_AGENT_COMMISION } from "../../service/apiConstants";
import apiClient from "../../service/apiClient";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import _ from "lodash";

const BaseAndMarkupInput = (props) => {
  const [loading, setLoad] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [commission, setCommission] = React.useState(props?.commission);
  const primaryButtonStyles =
    "bg-primary hover:bg-blue-700 text-white font-bold py-2 px-2 rounded";
  React.useEffect(() => {
    setCommission(props?.commission);
  }, [props?.commission]);

  const editAgentCommision = async (value) => {
    let flag = true;
    if (_.isEmpty(String(value.BasePersentage))) {
      flag = false;
      toast("Base Commision is Required", {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    }
    if (_.isEmpty(String(value.markupPercentage))) {
      flag = false;
      toast("Markup Commision is Required", {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    }

    if (value.BasePersentage > 1.8) {
      flag = false;
      toast("Base commision must be less then or eqal to 1.8", {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    }
    if (value.markupPercentage > 0.7) {
      flag = false;
      toast("Markup commision must be less then or eqal to 0.7", {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    }

    if (flag) {
      setLoad(true);
      await apiClient
        .post(EDIT_AGENT_COMMISION + props?.id, {
          commission: value,
        })
        .then((response) => {
          setLoad(false);
          if (response.status === 200) {
            props.fetchAllAgents();
            setIsEdit(false);
            toast(response.data.message, {
              theme: "dark",
              hideProgressBar: true,
              type: "success",
            });
            return;
          }

          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        })
        .catch((error) => {
          setLoad(false);
          toast(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Commision Not Updated",
            {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            }
          );
        });
    }
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <span className="mx-2">
        {isEdit ? (
          <>
            <div>
              <label>Base Percentage</label>
              <input
                className="w-20 text-sm px-2 py-0"
                value={commission?.BasePersentage}
                onChange={(e) =>
                  setCommission({
                    ...commission,
                    BasePersentage: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <label>Markup Percentage</label>
              <input
                className="w-20 text-sm px-2 py-0"
                value={commission.markupPercentage}
                onChange={(e) =>
                  setCommission({
                    ...commission,
                    markupPercentage: e.target.value,
                  })
                }
              />
            </div>
          </>
        ) : typeof props?.commission === "object" ? (
          <>
            <div>
              Base:- <strong>{props?.commission?.BasePersentage}</strong>
            </div>
            <div>
              Markup:- <strong>{props?.commission?.markupPercentage}</strong>
            </div>
          </>
        ) : (
          props?.commission
        )}
      </span>
      {isEdit ? (
        <>
          <button
            className={primaryButtonStyles}
            onClick={() => {
              editAgentCommision(commission);
            }}
          >
            <BiSave />
          </button>
          <button
            className={`${primaryButtonStyles} ml-1`}
            onClick={() => {
              setCommission(props?.commission);
              setIsEdit(false);
            }}
          >
            <MdOutlineCancel />
          </button>
        </>
      ) : (
        <button
          className={primaryButtonStyles}
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        >
          <BiEditAlt />
        </button>
      )}
    </>
  );
};

export default BaseAndMarkupInput;
