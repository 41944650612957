import React, { useState } from "react";
import greenCheckbox from "../../assets/svg/GREENcheckbox.svg";
import DropdownMenu from "./DropdownMenu";
import apiClient from "../../service/apiClient";
import {
  MANUAL_TRANSFER,
  RESEND_OTP,
  VELIDATION_CHECK,
  VERIFY_OTP,
} from "../../service/apiConstants";
import { GET_OTP } from "../../service/apiConstants";

import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import MobileField from "./MobileField";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { HiArrowNarrowLeft } from "react-icons/hi";

function ManualTransfer(props) {
  const [load, setLoad] = useState(false);
  const [formData, setFormData] = useState({
    accountNumber: "",
    confirm_accountNumber: "",
    ifsc_code: "",
    fullName: "",
    ben_mobile: "",
    fullName: "",
    bankName: "",
    ben_address: "",
    email: "",
    amount: "",
    type: "Select anyone",
    remark: "",
    benificiaryVerified: "",
  });
  const [formErrors, setFormErrors] = useState({
    accountNumber: "",
    confirm_accountNumber: "",
    ifsc_code: "",
    fullName: "",
    ben_mobile: "",

    address: "",
    email: "",
    amount: "",
    type: "",
    remark: "",
  });

  // State for otp
  const [showOTPField, setShowOTPField] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpError, setOtpError] = useState("");
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //navigate

  const navigate = useNavigate();

  const startTimer = () => {
    setIsTimerActive(true);
    setTimer(30);
  };

  useEffect(() => {
    let interval;

    if (isTimerActive) {
      interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          setIsTimerActive(false);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, isTimerActive]);

  const handleGetOTPClick = () => {
    //  implement  logic to request and send OTP via an API
    getOtp();
    setShowOTPField(true);
  };

  const getOtp = async () => {
    setLoad(true);
    await apiClient
      .post(GET_OTP)
      .then((response) => {
        setLoad(false);
        console.log("res===>", response);

        if (response.status === 200) {
          if (response.data.response.type === "error") {
            toast(response.data.response.message, {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
            return;
          }
          startTimer();

          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        console.log("error===>", error);
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Somthing wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  const handleResendOTP = async () => {
    //  implement logic to resend OTP here

    setLoad(true);
    await apiClient
      .post(RESEND_OTP)
      .then((response) => {
        setLoad(false);
        console.log("res===>", response);

        if (response.status === 200) {
          if (response.data.response.type === "error") {
            toast(response.data.response.message, {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
            return;
          }
          startTimer();

          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        console.log("error===>", error);
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Somthing wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  const verifyOtp = async () => {
    setOtpError("");
    if (otp === "") {
      setOtpError("Please enter OTP");
      return;
    } else if (!/^[0-9]+$/.test(otp)) {
      setOtpError("OTP must contain only numeric digits.");
      return;
    }
    if (otpError === "") {
      setLoad(true);
      await apiClient
        .post(VERIFY_OTP, {
          otp: otp,
        })
        .then((response) => {
          setLoad(false);
          console.log("res===>", response);

          if (
            response.status === 200 &&
            response.data.response.type === "success"
          ) {
            sendFund();
          }
        })
        .catch((error) => {
          setLoad(false);
          console.log("error===>", error);
          toast(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Somthing wrong",
            {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            }
          );
        });
    }
  };

  const sendFund = async () => {
    setLoad(true);
    await apiClient
      .post(MANUAL_TRANSFER, {
        accountNumber: formData.accountNumber,
        ifsc_code: formData.ifsc_code,
        amount: formData.amount,
        fullName: formData.fullName,
        ben_mobile: formData.ben_mobile,
        ben_address: formData.ben_address,
        ben_email: formData.email,
        transfer_type: formData.type,
        remarks: formData.remark,
      })
      .then((response) => {
        setLoad(false);
        console.log("res===>", response);

        if (response.status === 201 && response.data.status === true) {
          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "success",
          });

          navigate("/dashboard/banking");
        } else {
          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        console.log("error===>", error);
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Somthing wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  const handleChang = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // verifyBankDetail function

  const verifyBankDetail = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      //Handle api call
      console.log("Form submitted:", formData);

      // TEMPORARY POURPOSE TO USE NEFT
      // setFormData({
      //             ...formData,
      //             benificiaryVerified:true,
      //           });

      try {
        setLoad(true);
        await apiClient
          .post(VELIDATION_CHECK, {
            accountNumber: formData?.accountNumber,
            ifsc_code: formData?.ifsc_code,
            fullName: formData.fullName,
            ben_mobile: formData.ben_mobile,
          })
          .then((response) => {
            console.log(response);
            let data = response.data.response;
            let status = data.beneValidationResp.metaData.status;

            let message = response.data.message;
            if (status === "SUCCESS") {
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "success",
              });
              setFormData({
                ...formData,
                benificiaryVerified: data.beneValidationResp.metaData.status,
                fullName: data.beneValidationResp.resourceData.creditorName
                  ? data.beneValidationResp.resourceData.creditorName
                  : formData.fullName,
              });
            } else {
              toast(message, {
                theme: "dark",
                hideProgressBar: true,
                type: "warning",
              });
            }
            setLoad(false);
          })
          .catch((error) => {
            console.log(error);
            setFormData({ ...formData, benificiaryVerified: false });
            setLoad(false);
            let status = error.response.status;
            let msg = error.response.data.message;

            if (status === 200) {
              toast(msg, {
                theme: "dark",
                hideProgressBar: true,
                type: "error",
              });
              return;
            }

            if (status === 400) {
              toast(msg, {
                theme: "dark",
                hideProgressBar: true,
                type: "error",
              });
              return;
            }

            if (status === 500) {
              toast(msg, {
                theme: "dark",
                hideProgressBar: true,
                type: "error",
              });
              return;
            }

            toast("Server Error wait", {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
          });
      } catch (error) {
        setLoad(false);
        console.log(error);
      }
    } else {
      setFormErrors(errors);
    }
  };
  // validate form function
  const validateForm = () => {
    const bankIfscRegX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const errors = {};
    setFormErrors(errors);

    //for account number filed
    if (formData.accountNumber === "") {
      errors.accountNumber = "Account number is required";
    } else if (
      !(
        formData.accountNumber.length >= 9 &&
        formData.accountNumber.length <= 18
      )
    ) {
      errors.accountNumber = "Please enter valid length account number";
    } else if (!/^[0-9]+$/.test(formData.accountNumber)) {
      errors.accountNumber = "Please enter valid Account number";
    }

    if (formData.accountNumber !== formData.confirm_accountNumber) {
      errors.confirm_accountNumber = "Account number do not match";
    }

    // for ifsc number filed

    if (formData.ifsc_code === "") {
      errors.ifsc_code = "IFSC is required";
    } else if (!bankIfscRegX.test(formData.ifsc_code)) {
      errors.ifsc_code = "Please enter valid ifsc code";
    }

    if (formData.fullName === "") {
      errors.fullName = "Name is required";
    }

    // for mobile filed

    if (formData.ben_mobile === "") {
      errors.ben_mobile = "Mobile number is required";
    }

    return errors;
  };

  // validation check function

  const validationCheck = async () => {
    if (
      formData.benificiaryVerified === "" ||
      formData.benificiaryVerified === false
    ) {
      verifyBankDetail();
      return;
    }
    const errors = validateForm_sencon();
    if (Object.keys(errors).length === 0) {
      // Handle api call
      console.log("Form submitted:", formData);

      // call for otp
      handleGetOTPClick();
    } else {
      setFormErrors(errors);
    }
  };
  const validateForm_sencon = () => {
    const emailRegX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const errors = {};
    setFormErrors(errors);

    //for address filed

    if (formData.ben_address === "") {
      errors.address = "Address is required";
    }

    // for email filed

    if (formData.email === "") {
      errors.email = "Email is required";
    } else if (!emailRegX.test(formData.email)) {
      errors.email = "Please enter valid email";
    }

    // for amount filed

    if (formData.amount === "") {
      errors.amount = "Amount is required";
    } else if (!/^[0-9]+$/.test(formData.amount)) {
      errors.amount = "Please enter valid amount";
    }

    if (formData.type === "Select anyone") {
      errors.type = "Type is required";
    }

    return errors;
  };

  console.log(formData, otp);
  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          <div className="flex">
            <button className="text-primary hover:text-yellow-400 font-bold mr-4">
              <NavLink to="/dashboard/banking">
                <HiArrowNarrowLeft />
              </NavLink>
            </button>
            <h1 className="text-xl mr-10">Manual Transfer</h1>
          </div>

          <div className="w-full relative  ">
            <div className="w-10/12 mx-auto	mt-10 ">
              <div className="flex gap-3 mb-3">
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Account Number <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="accountNumber"
                      autoComplete="accountNumber--1"
                      value={formData.accountNumber}
                      onChange={handleChang}
                    />
                    {formErrors.accountNumber && (
                      <div className="text-red-700 text-sm">
                        {formErrors.accountNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Re-Enter Account Number{" "}
                      <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="confirm_accountNumber"
                      value={formData.confirm_accountNumber}
                      onChange={handleChang}
                      autoComplete="new-password"
                    />
                    {formErrors.confirm_accountNumber && (
                      <span className="text-red-700 text-sm">
                        {formErrors.confirm_accountNumber}
                      </span>
                    )}
                    <div
                      className="absolute right-[60px] top-1/3 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <BsEye /> : <BsEyeSlash />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 mb-3">
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      IFSC Code <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="ifsc_code"
                      value={formData.ifsc_code}
                      onChange={handleChang}
                    />
                    <button
                      className={`absolute bg-primary text-white px-[25px] py-[5px] rounded-lg text-xs top-[7px] right-[65px]`}
                      onClick={verifyBankDetail}
                      // disabled={formData.benificiaryVerified ? true : false}
                    >
                      Verify
                    </button>
                    {formErrors.ifsc_code && (
                      <div className="text-red-700 text-sm">
                        {formErrors.ifsc_code}
                      </div>
                    )}
                  </div>
                  {formData.bankName && (
                    <div className="flex gap-2 mt-1 items-center	">
                      <span className="text-sm text-blackBlur2">
                        {formData.bankName}
                      </span>
                      <img src={greenCheckbox} alt="" className="" />
                    </div>
                  )}
                </div>
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Name <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChang}
                    />
                  </div>
                  {formErrors.fullName && (
                    <div className="text-red-700 text-sm">
                      {formErrors.fullName}
                    </div>
                  )}
                  {/* {formData.fullName && (
                    <div className="flex gap-2 mt-1 items-center	">
                      <span className="text-sm text-blackBlur2">
                        Bank Verified Name
                      </span>
                      <img src={greenCheckbox} alt="" className="" />
                    </div>
                  )} */}
                </div>
              </div>
              <div className="flex gap-3 mb-3">
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Address <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="ben_address"
                      value={formData.ben_address}
                      onChange={handleChang}
                    />
                    {formErrors.address && (
                      <div className="text-red-700 text-sm">
                        {formErrors.address}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Email Address <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="email"
                      value={formData.email}
                      onChange={handleChang}
                      autoComplete={false}
                    />
                    {formErrors.email && (
                      <div className="text-red-700 text-sm">
                        {formErrors.email}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-3 mb-3">
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Mobile Number <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    {/* <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="ben_mobile"
                      value={formData.ben_mobile}
                      onChange={handleChang}
                    /> */}
                    <MobileField
                      formData={formData}
                      setFormData={setFormData}
                    />
                    {formErrors.ben_mobile && (
                      <div className="text-red-700 text-sm">
                        {formErrors.ben_mobile}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Amount <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChang}
                    />
                    {formErrors.amount && (
                      <div className="text-red-700 text-sm">
                        {formErrors.amount}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-3 mb-3">
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Type <span className="text-red-500 ">*</span>
                    </label>
                  </div>
                  <DropdownMenu formData={formData} setFormData={setFormData} />
                  {formErrors.type && (
                    <div className="text-red-700 text-sm">
                      {formErrors.type}
                    </div>
                  )}
                </div>
                <div className="w-1/2">
                  <div>
                    <label htmlFor="" className="text-sm text-blackBlur2">
                      Remarks
                    </label>
                  </div>
                  <div>
                    {/* <input
                type="text"
                className="bg-[#EFF1F999] rounded-lg border-0 w-11/12"
              /> */}
                    <textarea
                      id=""
                      rows={3}
                      placeholder="Text goes here"
                      className="bg-[#EFF1F999] rounded-lg border-0 w-11/12 resize-none"
                      name="remark"
                      value={formData.remark}
                      onChange={handleChang}
                    ></textarea>
                    {formErrors.remark && (
                      <div className="text-red-700 text-sm">
                        {formErrors.remark}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {formData.benificiaryVerified && (
                <div className="flex gap-2 mt-1 items-center justify-end	">
                  <span className="text-sm text-blackBlur2">
                    benificiaryVerified
                  </span>
                  <img src={greenCheckbox} alt="" className="" />
                </div>
              )}
              <div className="flex gap-3 ">
                {showOTPField && (
                  <>
                    <div className="w-1/2 flex items-center">
                      <div>
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOTP(e.target.value)}
                          className="bg-[#EFF1F999] rounded-lg border-0 mr-4 "
                        />
                      </div>
                      <div>
                        <button
                          onClick={handleResendOTP}
                          disabled={isTimerActive}
                          className={`${
                            isTimerActive
                              ? ""
                              : "bg-primary text-white rounded-lg text-xs px-3 py-2 "
                          }`}
                        >
                          Resend OTP{" "}
                          {isTimerActive ? `in ${timer} seconds` : ""}
                        </button>
                      </div>
                    </div>
                    <div className="w-1/2"></div>
                  </>
                )}
              </div>

              {otpError && (
                <div className="text-red-700 text-sm">{otpError}</div>
              )}
            </div>

            <div className="absolute right-[70px] mt-6">
              <button className="border border-primary text-base text-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-8 py-1 mr-3">
                <NavLink to="/dashboard/banking/">
                  {" "}
                  <span className="mr-2">&#8592;</span> Back
                </NavLink>
              </button>
              {showOTPField ? (
                <button
                  className="border border-primary text-base text-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-6 py-1"
                  onClick={verifyOtp}
                >
                  Send <span className="ml-2">&#8594;</span>
                </button>
              ) : (
                <button
                  className="border border-primary text-base text-primary hover:bg-primary hover:text-[#FFFFFF] font-bold rounded-md px-6 py-1"
                  onClick={validationCheck}
                >
                  Get Otp <span className="ml-2">&#8594;</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ManualTransfer;
