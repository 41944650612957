import { toast } from "react-toastify";
import {
  EDIT_COMMISION,
  KYCSTATUS,
  PAYMENTSTATUS,
  USERSTATUS,
} from "./apiConstants";
import apiClient from "./apiClient";
import { checkIsMobile, getDateString } from "./helper";
import { utils, writeFile } from "xlsx";
import _ from "lodash";

// for exporting functionality of users
export function downloadCSVOfUsers(array, selectedRows) {
  let newArray;
  if (_.isEmpty(selectedRows)) {
    newArray = array.map((item) => {
      return {
        Name: `${item.Customer_data.FirstName} ${item.Customer_data.LastName}`,
        KycDetails: item.kyc_status ? "verified" : "Not verified",
        Transfer: item.transfers.length,
        Bneficiary: item.beneficiary.length,
        ConviencePercentage: item.conviencePercentage,
        Mobiele: item.mobile,
        Transactions: item.transactions.length,
        CustomerId: item.Customer_data.Customer_id,
        AmountLimit: item.walletLimit,
        LimitAssigned: item.assignedLimit,
        Disabled: item.disabled ? "Enable" : "Disable",
        PaymentStatus: item.paymentDisabled ? "Enable" : "Disable",
        KYCStatus: item.kyc_status ? "Kyc Enable" : "Kyc Disable",
        CreatedAt: getDateString(item.created_At),
      };
    });
  } else {
    newArray = selectedRows.map((item) => {
      return {
        Name: `${item.Customer_data.FirstName} ${item.Customer_data.LastName}`,
        KycDetails: item.kyc_status ? "verified" : "Not verified",
        Transfer: item.transfers.length,
        Bneficiary: item.beneficiary.length,
        ConviencePercentage: item.conviencePercentage,
        Mobiele: item.mobile,
        Transactions: item.transactions.length,
        CustomerId: item.Customer_data.Customer_id,
        AmountLimit: item.walletLimit,
        LimitAssigned: item.assignedLimit,
        Disabled: item.disabled ? "Enable" : "Disable",
        PaymentStatus: item.paymentDisabled ? "Enable" : "Disable",
        KYCStatus: item.kyc_status ? "Kyc Enable" : "Kyc Disable",
        CreatedAt: getDateString(item.created_At),
      };
    });
  }

  let wb = utils.book_new();
  let ws = utils.json_to_sheet(newArray);
  utils.book_append_sheet(wb, ws, "export");
  writeFile(wb, "export.xlsx");
}

// for exporting functionality of Nonkyc users
export function downloadCSVOfNonKycUsers(array, selectedRows) {
  let newArray;
  if (_.isEmpty(selectedRows)) {
    newArray = array.map((item) => {
      return {
        Mobile_Number: item.MobileNo,
        IP_Address: item.ip_address,
        Device: checkIsMobile(item.device_info) ? "MOBILE" : "DESKTOP",
        Location:
          !_.isEmpty(item?.Location?.city) && !_.isEmpty(item?.Location?.region)
            ? `${item?.Location?.city}, ${item?.Location?.region}`
            : "N/A",
        CreatedAt: getDateString(item.created_At, true),
      };
    });
  } else {
    newArray = selectedRows.map((item) => {
      return {
        Mobile_Number: item.MobileNo,
        IP_Address: item.ip_address,
        Device: checkIsMobile(item.device_info) ? "MOBILE" : "DESKTOP",
        Location:
          !_.isEmpty(item?.Location?.city) && !_.isEmpty(item?.Location?.region)
            ? `${item?.Location?.city}, ${item?.Location?.region}`
            : "N/A",
        CreatedAt: item.created_At,
      };
    });
  }

  let wb = utils.book_new();
  let ws = utils.json_to_sheet(newArray);
  utils.book_append_sheet(wb, ws, "export");
  writeFile(wb, "export.xlsx");
}

// for exporting functionality of Transactions
export function downloadCSVOfAgentManage(array, selectedRows) {
  let newArray;

  if (_.isEmpty(selectedRows)) {
    newArray = array.map((item) => {
      return {
        Agent_Id: item?.Customer_data?.Agent_id,
        Agent_Name: item?.Customer_data?.FullName,
        Mobile: item?.mobile,
        Email_Address: item?.Email,
        Date_of_Onboarded: item?.created_At,
        Base_Commission: item?.Commission?.BasePersentage,
        Markup_Commission: item?.Commission?.markupPercentage,
        Status: item?.status,
      };
    });
  } else {
    newArray = selectedRows.map((item) => {
      return {
        Agent_Id: item?.Customer_data?.Agent_id,
        Agent_Name: item?.Customer_data?.FullName,
        Mobile: item?.mobile,
        Email_Address: item?.Email,
        Date_of_Onboarded: item?.created_At,
        Base_Commission: item?.Commission?.BasePersentage,
        Markup_Commission: item?.Commission?.markupPercentage,
        Status: item?.status,
      };
    });
  }

  let wb = utils.book_new();
  let ws = utils.json_to_sheet(newArray);
  utils.book_append_sheet(wb, ws, "export");
  writeFile(wb, "export.xlsx");
}

// for exporting functionality of Transactions
export function downloadCSVOfBanking(array, selectedRows) {
  let newArray;

  if (_.isEmpty(selectedRows)) {
    newArray = array.map((item) => {
      return {
        Transaction_ID: item?.transfer_id,
        Mobile: item?.ben_mobile,
        Email: item?.ben_email,
        Transaction_Date: item?.created_At,
        Account_No: item?.benificary_details?.acc_no,
        IFSC: item?.benificary_details?.IFSC_code,
        Amount: item?.amount,
        Type: item?.transfer_type,
        Status: item?.object_transfer?.metaData.status,
      };
    });
  } else {
    newArray = selectedRows.map((item) => {
      return {
        Transaction_ID: item?.transfer_id,
        Mobile: item?.ben_mobile,
        Email: item?.ben_email,
        Transaction_Date: item?.created_At,
        Account_No: item?.benificary_details?.acc_no,
        IFSC: item?.benificary_details?.IFSC_code,
        Amount: item?.amount,
        Type: item?.transfer_type,
        Status: item?.object_transfer?.metaData.status,
      };
    });
  }

  let wb = utils.book_new();
  let ws = utils.json_to_sheet(newArray);
  utils.book_append_sheet(wb, ws, "export");
  writeFile(wb, "export.xlsx");
}

// for exporting functionality of Reports

export function downloadCSVOfReport(array, selectedRows) {
  console.log("export");
}

// kyc status change
export const toggleKYCStatus = async (row, cbSuccess, cbError) => {
  await apiClient
    .post(KYCSTATUS, {
      authId: row?.firebase_uid,
      phone: row?.mobile,
    })
    .then((response) => {
      if (response.status === 200) {
        cbSuccess(response); // getUsers();
        toast(response.data.message, {
          theme: "dark",
          hideProgressBar: true,
          type: "success",
        });
        return;
      }

      toast(response.data.message, {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    })
    .catch((error) => {
      cbError(error); // setLoad(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "KYC Not Updated",
        {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        }
      );
    });
};

// user status change
export const toggleUserStatus = async (row, cbSuccess, cbError) => {
  await apiClient
    .post(USERSTATUS, {
      authId: row?.firebase_uid,
      phone: row?.mobile,
    })
    .then((response) => {
      if (response.status === 200) {
        cbSuccess(response); // getUsers();
        let msg = `Successfully ${response?.data?.response?.message} user`;

        toast(msg, {
          theme: "dark",
          hideProgressBar: true,
          type: "success",
        });
        return;
      }

      toast(response.data.message, {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    })
    .catch((error) => {
      cbError(error); // setLoad(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "User Status not Updated",
        {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        }
      );
    });
};

//payment status change
export const togglePaymentStatus = async (row, cbSuccess, cbError) => {
  await apiClient
    .post(PAYMENTSTATUS, {
      authId: row?.firebase_uid,
      phone: row?.mobile,
    })
    .then((response) => {
      if (response.status === 200) {
        cbSuccess(response); //getUsers();
        toast(response.data.message, {
          theme: "dark",
          hideProgressBar: true,
          type: "success",
        });
        return;
      }

      toast(response.data.message, {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    })
    .catch((error) => {
      cbError(error); // setLoad(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "User Status not Updated",
        {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        }
      );
    });
};

//edit convience percentage

export const editConviencePercentage = async (
  row,
  value,
  cbSuccess,
  cbError
) => {
  await apiClient
    .post(EDIT_COMMISION, {
      phone_number: row?.mobile,
      persentage: String(value),
    })
    .then((response) => {
      if (response.status === 200) {
        cbSuccess(response); //getUsers();
        toast(response.data.message, {
          theme: "dark",
          hideProgressBar: true,
          type: "success",
        });
        return;
      }

      toast(response.data.message, {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    })
    .catch((error) => {
      cbError(error); // setLoad(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Commision Not Updated",
        {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        }
      );
    });
};
