import React from "react";

function TransactionCard(props) {
  const thousandSeparatorRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  return (
    //   <div className="w-1/5 bg-gray-300 px-1 py-2 sm:px-3 sm:py-4 rounded-lg min-w-fit	">
    //     <h5 className="text-xs sm:text-sm font-semibold pb-0 sm:pb-2">
    //       {props?.title}
    //     </h5>
    //     <p className="font-bold text-md sm:text-2xl">
    //       {props?.number
    //         ? props?.data.toFixed(2).replace(thousandSeparatorRegex, "$1,")
    //         : props?.data}
    //     </p>
    //   </div>
    <div className="flex justify-between w-1/4 custom-box-shadow px-2 py-3 rounded-lg min-w-fit">
      <div className="mr-3">
        <p className="text-lg sm:text-3xl font-bold color text-[#00006B] mb-1 sm:mb-3">
          {props?.number
            ? props?.data.toFixed(2).replace(thousandSeparatorRegex, "$1,")
            : props?.data}
        </p>
        <p className="text-xs sm:text-sm font-bold">{props?.title}</p>
      </div>
      {/* <img src={props.icon} alt="" className="w-7 sm:w-10" /> */}
    </div>
  );
}

export default TransactionCard;
