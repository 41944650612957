import React, { useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contaxt/AuthContaxt";

function Profile(props) {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigation = useNavigate();
  return (
    <>
      <div className="w-2/5 flex justify-between items-center relative">
        <div>
          <p className="">Admin</p>
        </div>
        <button
          className="text-2xl"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <MdExpandMore />
        </button>
        {isOpen && (
          <div
            className="absolute top-full  right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg shadow-slate-400 ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="p-2" role="none">
              <ul>
                <li className="py-2 px-1">
                  <button
                    onClick={() => {
                      auth.logoutCurrentUser();
                      navigation("/");
                    }}
                  >
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;
