import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";

function Notification(props) {
  return (
    <div className="flex flex-col justify-center mr-3">
      <button className="bg-primary text-white px-[6px] py-[3px] rounded-full">
        <IoMdNotificationsOutline className="w-[20px] h-[20px]" />
      </button>
    </div>
  );
}

export default Notification;
