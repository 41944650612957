const AgentsSummary = (props) => {
  return (
    <div className="flex justify-between w-1/4 custom-box-shadow px-2 py-3 rounded-lg min-w-fit">
      <div className="mr-3">
        <p className="text-lg sm:text-3xl font-bold color text-[#00006B] mb-1 sm:mb-3">
          {props.agents}
        </p>
        <p className="text-xs sm:text-sm font-bold">{props?.title}</p>
      </div>
      <img src={props.icon} alt="" className="w-7 sm:w-10" />
    </div>
  );
};

export default AgentsSummary;
