import "./App.css";
import { RouterProvider } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { router } from "./routes/Route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider, QueryClient } from "react-query";
import Loader from "./components/Loader/Loader";
import Ipblocklayout from "../src/components/model/Ipblocklayout";
import apiClient from "./service/apiClient";
import { ADMIN_LOGIN_PREVENTION } from "./service/apiConstants";

const queryClient = new QueryClient();

function App() {
  const [loading, setLoading] = useState(false);

  const [BlockIP, setBlockIP] = useState(false);

  const loginBlock = async () => {
    setLoading(true);

    try {
      setLoading(true);
      await apiClient
        .post(ADMIN_LOGIN_PREVENTION)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setBlockIP(false);
          } else {
            setBlockIP(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setBlockIP(true);
        });

      // Check the HTTP status code to determine the API status
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
      console.log("API is likely down.");

      // Handle the error, which could indicate that the API is down
    }
  };

  useEffect(() => {
    // loginBlock();
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {/* {BlockIP ? (
          <Ipblocklayout />
        ) : ( */}
        <div className="font-lato overflow-hidden">
          <ToastContainer />
          {loading ? <Loader /> : <RouterProvider router={router} />}
        </div>
        {/* )} */}
      </QueryClientProvider>
    </>
  );
}

export default App;
