import { toast } from "react-toastify";
import apiClient from "./apiClient";
import { AGENTSTATUS } from "./apiConstants";

// user status change
export const toggleAgentStatus = async (row, cbSuccess, cbError) => {
  await apiClient
    .post(AGENTSTATUS, {
      authId: row?.firebase_uid,
      phone: row?.mobile,
    })
    .then((response) => {
      if (response.status === 200) {
        cbSuccess(response); // getUsers();
        let msg = `Successfully ${response?.data?.response?.message} agent`;
        toast(msg, {
          theme: "dark",
          hideProgressBar: true,
          type: "success",
        });
        return;
      }

      toast(response.data.message, {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    })
    .catch((error) => {
      cbError(error); // setLoad(false);
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "User Status not Updated",
        {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        }
      );
    });
};
