import React, { useEffect, useState } from "react";
import DocumentUpload from "./DocumentUpload";

const Documents = ({ data, setData, submitValidation }) => {
  const [selectedDoc, setSelectedDoc] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [error, setError] = useState("");
  let docsOptions = [
    { option: "Select Document", value: "" },
    { option: "Aadhar Image", value: "Aadhar" },
    { option: "Pan Image", value: "Pan" },
    { option: "Bank Passbook", value: "Bank Pass" },
  ];
  const [filteredOptions, setFilteredOptions] = useState(docsOptions);
  // const selectedOptions = data?.Documents.map(({ Doc_type }) => Doc_type);
  // const filteredOptions = docsOptions.filter(
  //   (item) => !selectedOptions.includes(item.value)
  // );

  // const [components, setComponents] = useState([]);
  // console.log("components", selectedDoc, data);

  //logic for when we click on add document button that add drag and drop image area.

  useEffect(() => {
    setSelectedOptions([...data?.Documents.map(({ Doc_type }) => Doc_type)]);
  }, [data?.Documents]);

  useEffect(() => {
    const filteredOptions = docsOptions.filter(
      (item) => !selectedOptions.includes(item.value)
    );
    setFilteredOptions([...filteredOptions]);
  }, [selectedOptions]);

  const handleAddComponent = () => {
    if (selectedDoc !== "") {
      onAddComponent(selectedDoc);
    } else setError("Select Document First");
  };

  const onAddComponent = (selectedDoc) => {
    for (let item of data?.Documents) {
      if (item?.Doc_type === selectedDoc) {
        return;
      }
    }

    if (selectedDoc !== "") {
      // setComponents([...components, { Doc_type: selectedDoc }]);
      setSelectedDoc("");
      setData({
        ...data,
        Documents: [...data?.Documents, { Doc_type: selectedDoc }],
      });
    }
  };

  const removeDoc = (Doc_type) => {
    // setComponents([...components.filter((item) => item?.Doc_type !== option)]);
    setData({
      ...data,
      Documents: [
        ...data?.Documents.filter((document) => document.Doc_type !== Doc_type),
      ],
    });
    // console.log(components);
  };
  const handleFileChange = (Doc_type, file) => {
    // setComponents([
    //   ...components.map((item) =>
    //     item?.Doc_type === option ? { ...item, file: file } : { ...item }
    //   ),
    // ]);
    setData({
      ...data,
      Documents: [
        ...data?.Documents.map((document) =>
          document.Doc_type === Doc_type
            ? { ...document, file: file }
            : { ...document }
        ),
      ],
    });
    setError("");
  };

  return (
    <div className="ms-2 mt-6">
      <h2 className="text-lg text-[#45464E] font-medium mb-6">Add Documents</h2>

      <div className="">
        {/**Dynamically add upload sections */}
        {/* {components.map((item, index) => ( */}
        {data?.Documents?.map((item, index) => (
          <div key={index}>
            <DocumentUpload
              item={item}
              index={index}
              Doc_type={item?.Doc_type}
              removeDoc={removeDoc}
              handleFileChange={(Doc_type, file) => {
                handleFileChange(Doc_type, file);
                submitValidation.uploadDocsErrors.filter(
                  (error) => error.index !== index
                );
              }}
              uploadDocsError={submitValidation.uploadDocsErrors.find(
                (error) => error.index === index
              )}
            />
          </div>
        ))}

        <div className="flex  justify-between w-11/12	min-[950px]:w-10/12 mx-auto mb-[5px]">
          <div className="w-5/12">
            <span className="text-sm text-gray-500 ">Upload new document</span>
          </div>
          <div className="w-5/12 flex justify-center"></div>
        </div>
        <div className=" flex justify-between w-11/12 min-[950px]:w-10/12 mx-auto mb-6">
          <div className="w-5/12">
            <select
              name="document"
              id=""
              className="py-[6px] text-base w-full border-0 rounded-lg bg-[#EFF1F999]"
              value={selectedDoc}
              onChange={(e) => {
                setSelectedDoc(e.currentTarget.value);
                setError("");
              }}
            >
              {/* <option value="">Select Document</option>
              <option value="Aadhar">Aadhar image</option>
              <option value="Pan">Pan image</option>
              <option value="Bank pass">Bank passbook image</option> */}
              {filteredOptions.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.option}
                </option>
              ))}
            </select>
            <p className="text-red-800 text-sm">{error}</p>
            {submitValidation.addDocsError && (
              <span className="text-red-800 text-sm">
                {submitValidation.addDocsError}
              </span>
            )}
          </div>
          <div className="w-5/12 h-10 flex justify-center">
            <button
              className="bg-primary py-[7px] text-base text-white px-3 rounded-lg"
              onClick={handleAddComponent}
            >
              &#x2B; Add new documents
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
