import _ from "lodash";

const UsersSummary = (props) => {
  if (_.isEmpty(props)) {
    return (
      <div className={"flex justify-between w-1/4 px-2 py-3"} key={props.key}>
        <div className="">
          <p className="text-3xl font-bold color text-[#00006B] mb-2">
            {props.count}
          </p>
          <p>{props?.title}</p>
        </div>
        <img src={props.icon} alt="" />
      </div>
    );
  }

  function handleClick() {
    props?.handleCardFilter && props.handleCardFilter(props.data);
  }
  return (
    <div
      className={`flex justify-between sm:min-w-[352px] custom-box-shadow px-2 py-2 pr-3 sm:px-3 sm:px-6 sm:py-6 rounded-lg cursor-pointer  ${
        props.active ? " border-2 border-black" : " border-2 border-transparent"
      }`}
      onClick={handleClick}
      key={props.key}
    >
      {/* {console.log(props.data)} */}
      <div className="mr-3">
        <p className="text-lg sm:text-3xl font-bold color text-[#00006B] mb-1 sm:mb-3">
          {props.count}
        </p>
        <p className="text-xs sm:text-sm font-medium text-[#858585]">
          {" "}
          {props?.title}
        </p>
      </div>
      <img src={props.icon} alt="" className="w-7 sm:w-10" />
    </div>
  );
};

export default UsersSummary;
