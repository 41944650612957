import React from 'react';

import { Chart as ChartJs,BarElement,CategoryScale,LinearScale,Tooltip,Legend } from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJs.register(BarElement,CategoryScale,LinearScale,Tooltip,Legend );

const option = {
    responsive : true,
    scales: {
        y: {
          beginAtZero: true
        }
      }
}

function TransactionBarChart(props) {
    const data = {
      labels: props?.createdDates,
      datasets: [
        {
          label: "",
          data: props?.amountData,
          backgroundColor : 'aqua',
          borderColor : 'black',
          borderWidth : 1  
        },
      ],
    };

    
    return (
      <div className="mt-8 mb-8">
        <Bar data={data} options={option}></Bar>
      </div>
    );
  }
  
  export default TransactionBarChart;
  