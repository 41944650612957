import React from "react";
import footerImg from "../../assets/img/Footer_cleanup.png";
import headerImg from "../../assets/img/Header_cleanup.png";
import logo from "../../assets/img/Logo.png";

import html2pdf from "html2pdf.js";
import { AiFillPrinter } from "react-icons/ai";
import { maskString } from "../../service/helper";

import moment from "moment/moment";

export default function PrintInvoicePDF({ row }) {
  const generatePDF = (row) => {
    // Convert the ISO timestamp to a Date object
    const date = new Date(row?.created_At);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month, day, and year from the Date object
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // var seconds = date.getSeconds();
    // var minutes = date.getMinutes();
    // var hour = date.getHours();

    // Create the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;

    //creating html file for invoice
    const modifiedDate = moment(row?.paymentDetails?.event_time).format(
      "MMMM D, YYYY h:mm A"
    );

    const element = document.createElement("div");
    element.innerHTML = "";
    let elementTwo = `<div style="width: 100%; margin: 0 auto;"><style>* {box-sizing : border-box;}</style>
          <header style="position: relative; padding: 60px 40px 0">
              <img src="${logo}" width="112" alt="payinstacard" style="">
            <img src="${headerImg}" alt="heder" style="position: absolute; top: 0; left:0; right: 0; bottom: 0; z-index: -1; width: 100%;">
            <div style="display: block; box-sizing: border-box; position: absolute; top: 40px; right: 130px; box-shadow: 0px 2px 4px grey; font-size: 16px; line-height: 20px; border-radius: 20px; padding: 0 10px 13px; background-color: #FFFFFF;">RECEIPT NO: #${
              row.OrderKeyId
            }</div>
        </header>
        <div style=" margin: 40px;">
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 1; margin-right: 20px; font-size: 14px;">
              <h6 style="margin-bottom: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Land Lord Information</span></h6>
              <!--<h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 10px;">${
                row?.benificary_details?.paymentInfo?.name
              }</h4>-->
              <div style="font-size: 13px;">
                  <p style="color: #19213D;"><span style="color: #5D6481;">Beneficiary Id: </span> ${
                    row?.benificary_details?.paymentInfo?.beneficier_id
                  }</p>
                  ${
                    row?.benificary_details?.paymentInfo?.phone
                      ? `<p style="color: #19213D;"><span style="color: #5D6481;">Phone: </span> ${row?.benificary_details?.paymentInfo?.phone}</p>`
                      : ""
                  }
                  ${
                    row?.benificary_details?.paymentInfo?.address
                      ? `<p style="color: #19213D;"><span style="color: #5D6481;">Address: </span> ${row?.benificary_details?.paymentInfo?.address}</p>`
                      : ""
                  }
                  <p style="color: #19213D;"><span style="color: #5D6481;">Bank A/c No: </span> ${
                    maskString(
                      row?.benificary_details?.paymentInfo?.bankAccount
                    ) || "N/A"
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">IFSC: </span> ${
                    row?.benificary_details?.paymentInfo?.ifsc_code
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">UPI: </span> ${
                    row?.benificary_details?.paymentInfo?.upi_code || "N/A"
                  }</p>
              </div>
            </div>
        
            <div style="font-size: 14px;">
              <h6 style="margin-bottom: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Customer Information</span></h6>
              <h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 5px;">${
                row?.customerData?.FirstName + " " + row?.customerData?.LastName
              }</h4>
              <div style="font-size: 13px;">
                <p style="color: #19213D;"><span style="color: #5D6481;">Phone: </span> ${
                  row?.customerData?.MobileNo
                }</p>
              </div>
              
              <h6 style="margin-bottom: 5px;margin-top: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Payment Information</span></h6>
              <h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 10px;">${formattedDate}</h4>
              <div style="font-size: 13px;">
                  <p style="color: #19213D;"><span style="color: #5D6481;">Payment Method: </span> ${
                    row?.PaymentMethod === "credit_card"
                      ? "Credit Card"
                      : "Debit Card"
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">Transaction Id:
                  </span> ${row?.PaymentTransactionRefNo}</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">Order Id: </span> ${
                    row?.OrderId
                  }</p>
              </div>
            </div>
          </div>
          <table style="width: 100%; font-size: 14px; margin-top: 40px; margin-bottom: 20px;">
            <tr style="background-color: #F6F8FC; border-radius: 8px;">
              <th style="text-align: left; padding: 15px 5px; font-weight: 600;">Service</th>
              <th style="text-align: right; padding: 5px;">Total</th>
            </tr>
            <tr>
            <td style="text-align: left; padding: 15px 5px; font-weight: 600;">Rent Amount</td>
            <td style="text-align: right; padding: 5px;">Rs. ${
              row?.benificary_details?.Amount
            }</td>
            </tr>
          </table>
        
          <table style="width: 300px;font-size: 14px;  margin-left: auto; margin-top: 20px; margin-bottom: 20px;">
            <tr>
              <td style="text-align: left; padding: 2px 5px;font-weight: 600;">Subtotal</td>
              <td style="text-align: right; padding: 2px 5px;">Rs. ${
                row?.benificary_details?.Amount
              }</td>
            </tr>
            <tr>
              <td style="text-align: left; padding: 5px 5px;font-weight: 600;">Convenience Fee (Inclusive of gst) </td>
              <td style="text-align: right; padding: 5px 5px;">Rs. ${
                row?.benificary_details?.conviFees
              }</td>
            </tr>
            <tr style="border-radius: 8px; background: var(--neutral-colors-200, #F6F8FC);">
              <td style="text-align: left; padding: 15px 5px 10px;font-weight: 600;">Invoice Total</td>
              <td style="text-align: right; padding: 15px 5px 10px;">Rs. ${
                row?.benificary_details?.TotalAmount
              }</td>
            </tr>
          </table>
        </div>
        <footer style="position: relative; padding: 40px; font-size: 14px;">
          <img src="${footerImg}" alt="footer" style="position: absolute; top: 0; left:0; right: 0; bottom: 0; width: 100%; " />
          <div style="">
            <h5 style="font-weight: 600;">Payinstacard</h5>
            <p style="color: #5D6481; font-size: 13px; "><a href="www.payinstacard.com">www.payinstacard.com</a></p>
            <p style="color: #5D6481; font-size: 13px; "><a href="mailto:care@payinstacard.com">care@payinstacard.com</a> / <a href="tel:18005716878">1800 571 6878</a></p>
          </div>
        </footer></div>
        `;



        let elementTwo2 = `<div style="width: 100%; margin: 0 auto;"><style>* {box-sizing : border-box;}</style>
          <header style="position: relative; padding: 60px 40px 0">
        
            <img src="${headerImg}" alt="heder" style="position: absolute; top: 0; left:0; right: 0; bottom: 0; z-index: -1; width: 100%;">
            <div style="display: block; box-sizing: border-box; position: absolute; top: 40px; right: 130px; box-shadow: 0px 2px 4px grey; font-size: 16px; line-height: 20px; border-radius: 20px; padding: 0 10px 13px; background-color: #FFFFFF;">RECEIPT NO: #${
              row.OrderKeyId
            }</div>
        </header>
        <div style=" margin: 40px;">
          <div style="position:relative;top:0px;z-index:10;padding:12px;background:#fff;border-radius:24px;width:fit-content;box-shadow: 0px 2px 4px 0px rgba(48, 95, 151, 0.02)';overflow:visible;"> 
              <img src="${logo}" width="112" alt="payinstacard" style="">
              </div>
          <div style="display: flex; justify-content: space-between;flex-grow:1;">
            <div style="flex: 1; margin-right: 20px; font-size: 14px;">
              <h6 style="margin-bottom: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Land Lord Information</span></h6>
              <!--<h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 10px;">${
                row?.benificary_details?.paymentInfo?.name
              }</h4>-->
              <div style="font-size: 13px;">
                  <p style="color: #19213D;"><span style="color: #5D6481;">Beneficiary Id: </span> ${
                    row?.benificary_details?.paymentInfo?.beneficier_id
                  }</p>
                  ${
                    row?.benificary_details?.paymentInfo?.phone
                      ? `<p style="color: #19213D;"><span style="color: #5D6481;">Phone: </span> ${row?.benificary_details?.paymentInfo?.phone}</p>`
                      : ""
                  }
                  ${
                    row?.benificary_details?.paymentInfo?.address
                      ? `<p style="color: #19213D;"><span style="color: #5D6481;">Address: </span> ${row?.benificary_details?.paymentInfo?.address}</p>`
                      : ""
                  }
                  <p style="color: #19213D;"><span style="color: #5D6481;">Bank A/c No: </span> ${
                    maskString(
                      row?.benificary_details?.paymentInfo?.bankAccount
                    ) || "N/A"
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">IFSC: </span> ${
                    row?.benificary_details?.paymentInfo?.ifsc_code
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">UPI: </span> ${
                    row?.benificary_details?.paymentInfo?.upi_code || "N/A"
                  }</p>
              </div>
            </div>
        
            <div style="font-size: 14px;">
              <h6 style="margin-bottom: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Customer Information</span></h6>
              <h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 5px;">${
                row?.customerData?.FirstName + " " + row?.customerData?.LastName
              }</h4>
              <div style="font-size: 13px;">
                <p style="color: #19213D;"><span style="color: #5D6481;">Phone: </span> ${
                  row?.customerData?.MobileNo
                }</p>
              </div>
              
              <h6 style="margin-bottom: 5px;margin-top: 10px;"><span style="border-radius: 4px; background: rgba(0, 0, 107, 0.10);color: #00006B; font-size: 12px; padding: 0 10px 13px;">Payment Information</span></h6>
              <h4 style="text-transform: capitalize; color: #19213D; font-size: 16px; font-weight: 700; margin-bottom: 10px;">${modifiedDate}</h4>
              <div style="font-size: 13px;">
                  <p style="color: #19213D;"><span style="color: #5D6481;">Payment Method: </span> ${
                    row?.PaymentMethod === "credit_card"
                      ? "Credit Card"
                      : "Credit Card"
                  }</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">Transaction Id:
                  </span> ${row?.PaymentTransactionRefNo}</p>
                  <p style="color: #19213D;"><span style="color: #5D6481;">Order Id: </span> ${
                    row?.OrderId
                  }</p>
              </div>
            </div>
          </div>
          <table style="width: 100%; font-size: 14px; margin-top: 40px; margin-bottom: 20px;">
            <tr style="background-color: #F6F8FC; border-radius: 8px;">
              <th style="text-align: left; padding: 15px 5px; font-weight: 600;">Service</th>
              <th style="text-align: right; padding: 5px;">Total</th>
            </tr>
            <tr>
            <td style="text-align: left; padding: 15px 5px; font-weight: 600;">Rent Amount</td>
            <td style="text-align: right; padding: 5px;">Rs. ${
              row?.benificary_details?.Amount
            }</td>
            </tr>
          </table>
        
          <table style="width: 300px;font-size: 14px;  margin-left: auto; margin-top: 20px; margin-bottom: 20px;">
            <tr>
              <td style="text-align: left; padding: 2px 5px;font-weight: 600;">Subtotal</td>
              <td style="text-align: right; padding: 2px 5px;">Rs. ${
                row?.benificary_details?.Amount
              }</td>
            </tr>
            <tr>
              <td style="text-align: left; padding: 5px 5px;font-weight: 600;">
              
              <p>Convenience</p></td>
            </tr>
            <tr>
              <td style="text-align: left; padding: 5px 5px;font-weight: 600;">
              
              <p>Fee (Inclusive of gst)</p></td>
              <td style="text-align: right; padding: 5px 5px;whitespace:nowrap">Rs. ${
                row?.benificary_details?.conviFees
              }</td>
            </tr>
            <tr style="border-radius: 8px; background: var(--neutral-colors-200, #F6F8FC);">
              <td style="text-align: left; padding: 15px 5px 10px;font-weight: 600;">Invoice Total</td>
              <td style="text-align: right; padding: 15px 5px 10px;">Rs. ${
                row?.benificary_details?.TotalAmount
              }</td>
            </tr>
          </table>
        </div>
                <div style="height:220px;"></div>
        <footer style="position: relative;bottom:0; padding: 40px; font-size: 14px;">
          <img src="${footerImg}" alt="footer" style="position: absolute; top: 0; left:0; right: 0; bottom: 0; width: 100%; " />
          <div style="">
            <h5 style="font-weight: 600;">Payinstacard</h5>
            <p style="color: #5D6481; font-size: 13px; "><a href="www.payinstacard.com">www.payinstacard.com</a></p>
            <p style="color: #5D6481; font-size: 13px; "><a href="mailto:care@payinstacard.com">care@payinstacard.com</a> / <a href="tel:18005716878">1800 571 6878</a></p>
          </div>
        </footer>

     
        

      
        </div>
        `;
    element.innerHTML = "<body>" + elementTwo2 + "</body>";

    html2pdf()
      .from(element)
      .set({
        filename: "transactionInvoice.pdf",
        image: { type: "jpeg", quality: 0.95 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 300,
          letterRendering: true,
        },
      })
      .toPdf()
      .get("pdf")
      .save();
  };

  return (
    <button
      onClick={() => {
        generatePDF(row);
      }}
      className="align-middle	"
    >
      <AiFillPrinter className="text-primary hover:text-blue-700 w-6 h-6" />
    </button>
  );
}
