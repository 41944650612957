import _ from "lodash";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, Outlet, useLocation } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { StyleSheetManager } from "styled-components";
import agentMangaeIcon from "../assets/svg/Agent_management.svg";
import activeButton from "../assets/svg/agent_active_button.svg";
import inActiveButton from "../assets/svg/agent_inactive_button.svg";
import downloadIcon from "../assets/svg/download.svg";
import Loader from "../components/Loader/Loader";
import Pagination from "../components/Pagination/Pagination";
import BaseAndMarkupInput from "../components/forms/BaseAndMarkupInput";
import AgentsSummary from "../components/model/AgentsSummary";
import TransactionFilters from "../components/transactions/TransactionFilters";
import { toggleAgentStatus } from "../service/agentApis";
import apiClient from "../service/apiClient";
import { FETCH_AGENT } from "../service/apiConstants";
import { getDateString } from "../service/helper";
import { shouldForwardProp } from "../service/shouldForwardProp";
import { downloadCSVOfAgentManage } from "../service/usersApis";

const Export = ({ onExport }) => (
  <button
    className="bg-primary text-sm sm:text-base text-white hover:bg-blue-700 text-white font-bold items-center px-2 sm:py-2 sm:px-4 rounded font-normal h-[35px] sm:h-[42px] flex gap-1 sm:gap-2"
    onClick={(e) => onExport(e.target.value)}
  >
    <img src={downloadIcon} alt="" className=" w-4 h-4 sm:w-6 sm:h-6" />
    <span>Export</span>
  </button>
);

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="w-[270px]">
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          id="search"
          type="text"
          placeholder="Search by agent ID or name"
          aria-label="Search"
          value={filterText}
          onChange={onFilter}
          className="relative m-0  block  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary h-[42px]"
        />

        <button
          className="relative z-[2] flex items-center rounded-r bg-primary hover:bg-blue-700 px-2 py-2 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={onClear}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </>
);

// custom style for data table

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {},
  },
};

function AgentsManage(props) {
  const location = useLocation();

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  // filter through date
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  });

  // filter through search bar
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  React.useEffect(() => {
    let mapData = data.map((item, index) => {
      return { ...item, ["Index"]: data.length - index };
    });
    if (filterText !== "") {
      mapData = mapData.filter((item) => {
        return (
          item.Customer_data?.FullName?.toLowerCase().includes(
            filterText.toLowerCase()
          ) ||
          item.Customer_data?.Agent_id?.toLocaleLowerCase().includes(filterText)
        );
      });
    }

    if (!_.isEmpty(dateRange.startDate) && !_.isEmpty(dateRange.endDate)) {
      const startDate = new Date(dateRange.startDate);
      startDate.setUTCHours(0, 0, 0, 0);
      const endDate = new Date(dateRange.endDate);
      endDate.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.created_At);
        return (
          startDate.getTime() <= itemDate.getTime() &&
          itemDate.getTime() <= endDate.getTime()
        );
      });
    }

    setFilteredItems(mapData.reverse());
  }, [data, filterText, dateRange]);

  // get agents list from sever
  const fetchAllAgents = async () => {
    setLoad(true);

    await apiClient
      .get(FETCH_AGENT)
      .then((response) => {
        setLoad(false);
        console.log("response==========>", response);

        setData([...response?.data?.response?.agents_array]);
      })
      .catch((error) => {
        setLoad(false);

        // console.log(error);
      });
  };
  useEffect(() => {
    fetchAllAgents();
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const handleValueChange = (newValue) => {
      setDateRange(newValue);
    };

    return (
      <>
        {/* <div className="w-full flex flex-col xl:flex-row justify-between items-left mb-5">
          <div className="mb-2">List</div> */}
        <div className="w-full flex flex-col xl:flex-row justify-between items-center mb-8 p-0">
          <h3 className="mb-2 sm:mb-0">List</h3>

          {/* <div className="w-full flex flex-col lg:flex-row item-stratch flex-wrap gap-3 sm:gap-4 justify-end md:gap-5 items-center md:items-start"> */}

          <div className="flex flex-col lg:flex-row flex-wrap lg:items-start item-stratch gap-3 sm:gap-4 md:gap-5 items-center">
            <div className="w-[265px]">
              <Datepicker
                placeholder={"Select Date"}
                value={dateRange}
                onChange={handleValueChange}
                useRange={false}
                maxDate={new Date()}
                primaryColor={"blue"}
                inputClassName="w-[265px] px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base h-[35px] sm:h-[42px] rounded-md focus:ring-0 font-normal bg-white-100 dark:placeholder:text-black-100"
                toggleClassName="absolute bg-primary hover:bg-blue-700 rounded-r-md text-white right-0 h-full px-2 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              />
            </div>
            <div>
              <TransactionFilters
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
              />
            </div>
            <div>
              <Export
                onExport={() =>
                  downloadCSVOfAgentManage(filteredItems, selectedRows)
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [
    filterText,
    resetPaginationToggle,
    dateRange,
    filteredItems,
    selectedRows,
  ]);

  //sort function for date column

  const dateSortFun = (rowA, rowB) => {
    const dateA = new Date(rowA.created_At);
    const dateB = new Date(rowB.created_At);
    if (dateA > dateB) {
      return 1;
    }
    if (dateB > dateA) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: " SL",
      cell: (row, index) => row.Index,
    },
    {
      name: "Agent ID",
      selector: (row) => row.Customer_data?.Agent_id,
      grow: 3,
    },
    {
      name: "Agent Name",
      selector: (row) => row?.Customer_data?.FullName,
      sortable: "true",
      width: "150px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      width: "150px",
    },
    {
      name: "Email Address",
      selector: (row) => row.Email,
      grow: 4,
    },
    {
      // name: "Date of Onboarded",
      name: (
        <div className="text-center">
          Date of
          <br />
          Onboarded
        </div>
      ),
      selector: (row) => getDateString(row.created_At),
      sortable: "true",
      width: "140px",
      sortFunction: dateSortFun,
    },
    {
      name: (
        <div className="text-center">
          Convience
          <br />
          Percentage
        </div>
      ),
      // cell: (row) => (
      //   <>
      //     <div className="flex flex-col">
      //       <p className="mb-1">
      //         Base Commission:-{row.Commission.BasePersentage}
      //       </p>
      //       <p>Markup Commission:-{row.Commission.markupPercentage}</p>
      //     </div>
      //   </>
      // ),
      cell: (row) => (
        <>
          <BaseAndMarkupInput
            commission={row.Commission}
            id={row._id}
            fetchAllAgents={fetchAllAgents}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.disabled == false) {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              <span> &#8226;</span> Active
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
              <span> &#8226;</span> in Active
            </span>
          );
        }
      },
      center: "true",
      width: "130px",
    },
    {
      // name: "Disabled",
      name: (
        <div className="text-center">
          Account
          <br />
          Status
        </div>
      ),
      cell: (row) => (
        <>
          {row.disabled === true ? (
            <button
              onClick={() => {
                setLoad(true);
                toggleAgentStatus(
                  row,
                  (response) => {
                    setLoad(false);
                    fetchAllAgents();
                  },
                  (error) => {
                    setLoad(false);
                  }
                );
              }}
            >
              <img src={inActiveButton} className="w-8 h-8" alt="" />
            </button>
          ) : (
            <button
              onClick={() => {
                setLoad(true);
                toggleAgentStatus(
                  row,
                  (response) => {
                    setLoad(false);
                    fetchAllAgents();
                  },
                  (error) => {
                    setLoad(false);
                  }
                );
              }}
            >
              <img src={activeButton} className="w-8 h-8" alt="" />
            </button>
          )}
        </>
      ),
    },
  ];

  //toggle active

  const toggleActive = (row) => {
    console.log("toggle");
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  return (
    <main className="p-4 w-full">
      {/* Header  */}
      {location.pathname === "/dashboard/agentsmanagement/addAgent" ||
      location.pathname === "/dashboard/agentsmanagement/agenttransactions" ||
      location.pathname === "/dashboard/agentsmanagement/agentpayouts" ||
      location.pathname ===
        "/dashboard/agentsmanagement/agentwithdrawpayouts" ? (
        <Outlet></Outlet>
      ) : (
        <>
          <div className="flex flex-col mb-10 md:mb-2 md:flex-row justify-between items-center">
            <h2 className="text-xl font-semibold mb-2 sm:mb-4 xs:mb-4">
              Manage Agents
            </h2>
            <div>
              {/* <button className="bg-primary text-white py-1 px-3 sm:py-2 rounded-lg rounded-6 sm:px-6"> */}
              <Link
                to="/dashboard/agentsmanagement/addAgent"
                className="bg-primary text-white cursor-pointer py-2 px-3 sm:py-2 text-sm sm:text-base rounded-lg rounded-6 px-4 sm:px-6"
              >
                <span className="mr-2 sm:mr-4">+</span>Add New Agent
              </Link>
              {/* </button> */}
            </div>
          </div>

          {/* agents summary */}
          <div className="flex flex-wrap justify-start gap-3 sm:gap-6 mb-2 mt-4 sm:mb-6 sm:mt-6">
            <AgentsSummary
              agents={filteredItems?.length}
              title={"Total Agents"}
              icon={agentMangaeIcon}
            />
            <AgentsSummary
              agents={
                filteredItems.filter((item) => item.disabled === false).length
              }
              title={"Active"}
              icon={agentMangaeIcon}
            />
            <AgentsSummary
              agents={
                filteredItems.filter((item) => item.disabled === true).length
              }
              title={"Inactive"}
              icon={agentMangaeIcon}
            />
          </div>
          {/** Agents data table  */}
          <div className="agent-table mt-3 sm:mt-10">
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationComponent={Pagination}
                progressPending={load}
                progressComponent={<Loader />}
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
              />
            </StyleSheetManager>
          </div>
        </>
      )}

      {/* {load ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-m font-semibold mb-2 text-primary text-bold">
            Transaction statistics
          </h2>
          <hr />
          <div className="flex justify-between mt-2 mb-2">
            <TransactionCard data={data.length} title={"Total Transactions"} />
            <TransactionCard
              data={
                [...data.filter((row) => row.OrderPaymentStatus === "200")]
                  .length
              }
              title={"Total successful Transactions"}
            />
            <TransactionCard
              number={true}
              data={data
                .filter((data) => data.OrderPaymentStatus === "200")
                .reduce(
                  (total, transaction) =>
                    total +
                    parseInt(transaction.benificary_details.TotalAmount),
                  0
                )}
              title={"Total Amount"}
            />
            <TransactionCard
              number={true}
              data={data
                .filter((data) => data.OrderPaymentStatus === "200")
                .reduce(
                  (total, transaction) =>
                    total + parseInt(transaction.benificary_details.Amount),
                  0
                )}
              title={"Beneficiary Credited Amount"}
            />
          </div>

          <hr />
          <TransactionBarChart
            amountData={[...data.map((data) => data.OrderAmount)]}
            createdDates={[
              ...data.map((data) => {
                const dateString = data.created_At;
                const dateObject = new Date(dateString);

                // Get the date portion as a string (YYYY-MM-DD)
                const date = dateObject.toISOString().split("T")[0];
                return date;
              }),
            ]}
          />

          <h2 className="text-m font-semibold mb-2 text-primary text-bold">
            Transaction Admin panel
          </h2>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              progressPending={load}
              progressComponent={<Loader />}
              fixedHeader
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </StyleSheetManager>
        </>
      )} */}
    </main>
  );
}

export default AgentsManage;
