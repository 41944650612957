import React, { useState, useEffect } from "react";
import AgentsSummary from "../components/model/AgentsSummary";
import DataTable from "react-data-table-component";
import TransactionFilters from "../components/transactions/TransactionFilters";
import downloadIcon from "../assets/svg/download.svg";
import _ from "lodash";
import { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "../service/shouldForwardProp";
import { Link, Outlet, useLocation } from "react-router-dom";
import BankIcon from "../assets/svg/ManualTransferSvg/BankIcon.svg";
import view from "../assets/svg/view.svg";
import Loader from "../components/Loader/Loader";

import DownloadIcon from "../assets/svg/ManualTransferSvg/DownloadIcon.svg";
import { getDateString } from "../service/helper";

import apiClient from "../service/apiClient";
import { FETCH_TRANSFER } from "../service/apiConstants";
import { CHECK_ACCOUNT_BALANCE } from "./../service/apiConstants";
import { FiRefreshCw } from "react-icons/fi";
import { toast } from "react-toastify";
import Pagination from "../components/Pagination/Pagination";
import { downloadCSVOfBanking } from "../service/usersApis";
import Datepicker from "react-tailwindcss-datepicker";

const Export = ({ onExport }) => (
  <button
    className="bg-primary text-sm sm:text-base text-white hover:bg-blue-700 text-white font-bold items-center px-2 sm:py-2 sm:px-4 rounded font-normal h-[35px] sm:h-[42px] flex gap-1 sm:gap-2"
    onClick={(e) => onExport(e.target.value)}
  >
    <img src={downloadIcon} alt="" className=" w-4 h-4 sm:w-6 sm:h-6" />
    <span>Export</span>
  </button>
);

// custom style for data table
const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {},
  },
};

function Banking(props) {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [bankBalLoad, setBankBalLoad] = useState(false);
  const [bankBal, setBankBal] = useState("0");

  // filter through search bar
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  });

  React.useEffect(() => {
    let mapData = data.map((item, index) => {
      return { ...item, ["Index"]: data.length - index };
    });
    if (filterText !== "") {
      mapData = mapData.filter((item) => {
        return item.transfer_id
          .toLowerCase()
          .includes(filterText.toLowerCase());
      });
    }
    if (!_.isEmpty(dateRange.startDate) && !_.isEmpty(dateRange.endDate)) {
      const startDate = new Date(dateRange.startDate);
      startDate.setUTCHours(0, 0, 0, 0);
      const endDate = new Date(dateRange.endDate);
      endDate.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.created_At);
        return (
          startDate.getTime() <= itemDate.getTime() &&
          itemDate.getTime() <= endDate.getTime()
        );
      });
    }

    setFilteredItems(mapData.reverse());
  }, [data, filterText, dateRange]);

  // get all RentPayouts from api backend
  const getTransfers = async () => {
    setLoad(true);

    // setTableDataLoad(true);
    await apiClient
      .get(FETCH_TRANSFER)
      .then((response) => {
        setLoad(false);
        if (response.status === 200) {
          setData(response.data.response.transfers_array);
          return;
        }

        toast(response.data.message, {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
        // setTableDataLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        // setTableDataLoad(false);
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Somthing wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  };

  useEffect(() => {
    getTransfers();
  }, [location.pathname]);

  const checkbalance = async () => {
    setBankBalLoad(true);
    await apiClient
      .get(CHECK_ACCOUNT_BALANCE)
      .then((response) => {
        setBankBalLoad(false);
        const status = response.status;

        if (status === 200) {
          setBankBal(response.data.response.avilableBal);
        }
      })
      .catch((error) => {
        setBankBalLoad(false);
        setBankBal("ERROR OCCURED");

        if (!error.status) {
          toast("server busy", {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
          return;
        }
        const status = error.response.status;
        const message = error.response.data.message;

        if (status === 400) {
          toast(message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    checkbalance();
  }, []);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const handleValueChange = (newValue) => {
      setDateRange(newValue);
    };

    return (
      <>
        <div className="w-full flex flex-col xl:flex-row justify-between items-left mb-5 p-0">
          <h3 className="mb-2">Users List</h3>
          <div className=" flex flex-col lg:flex-row item-stratch flex-wrap gap-3 sm:gap-4 md:gap-5 items-center md:items-start">
            <div className="w-[265px]">
              <Datepicker
                placeholder={"Select Date"}
                value={dateRange}
                onChange={handleValueChange}
                useRange={false}
                maxDate={new Date()}
                primaryColor={"blue"}
                inputClassName="w-[265px] px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base h-[35px] sm:h-[42px] rounded-md focus:ring-0 font-normal bg-white-100 dark:placeholder:text-black-100"
                toggleClassName="absolute bg-primary hover:bg-blue-700 rounded-r-md text-white right-0 h-full px-2 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
              />
            </div>
            <div>
              <TransactionFilters
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
              />
            </div>
            <div>
              <Export
                onExport={() =>
                  downloadCSVOfBanking(filteredItems, selectedRows)
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, filteredItems, selectedRows]);

  //sort function for date column

  const dateSortFun = (rowA, rowB) => {
    const dateA = new Date(rowA.created_At);
    const dateB = new Date(rowB.created_At);
    if (dateA > dateB) {
      return 1;
    }
    if (dateB > dateA) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => row.Index,
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transfer_id,
      width: "150px",
    },
    {
      name: "Contact Information",
      cell: (row) => (
        <div>
          <p className="mb-1">{row.ben_mobile}</p>
          <p>{row.ben_email}</p>
        </div>
      ),
      width: "200px",
    },
    {
      name: "Transaction Date",
      selector: (row) => getDateString(row.created_At),
      sortable: "true",
      width: "150px",
      sortFunction: dateSortFun,
    },
    {
      name: "Beneficiary",
      cell: (row) => (
        <div>
          <p className="mb-1">{row.benificary_details.acc_no}</p>
          <p>{row.benificary_details.IFSC_code}</p>
        </div>
      ),
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Type",
      selector: (row) => row.transfer_type,
    },

    {
      name: "Status",
      selector: (row) => {
        if (row.object_transfer.metaData.status === "SUCCESS") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              <span> &#8226;</span> Success
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
              <span> &#8226;</span> Failed
            </span>
          );
        }
      },
      center: "true",
      width: "130px",
    },

    {
      name: "Bank Status",
      selector: (row) => {
        if (row.object_transfer.metaData.status === "SUCCESS") {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              <span> &#8226;</span> {row.status}
            </span>
          );
        }
      },
      center: "true",
      width: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <img src={view} alt="" className="w-8 h-8 mr-2" />
          <img src={DownloadIcon} alt="" className="w-8 h-8" />
        </>
      ),
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  return (
    <main className="p-4 w-full">
      {/* Header  */}
      {load ? (
        <Loader />
      ) : location.pathname === "/dashboard/banking/manualtransfer" ? (
        <Outlet></Outlet>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center flex-wrap">
            <h2 className="text-xl font-semibold mb-2 md:mb-4">
              Manage Manual Transfers
            </h2>
            <div>
              {/* <button className="bg-primary text-white py-1 px-3 sm:py-2 rounded-lg rounded-6 sm:px-6"> */}
              <Link
                to="/dashboard/banking/manualtransfer"
                className="bg-primary cursor-pointer text-white py-1 px-3 sm:px-6 sm:py-2 text-sm sm:text-base rounded-lg rounded-6"
              >
                <span className="mr-2 sm:mr-4">+</span>Make New Transaction
              </Link>
              {/* </button> */}
            </div>
          </div>

          {/* agents summary */}
          <div className="flex flex-wrap justify-start gap-3 sm:gap-6 mb-2 mt-4 sm:mb-6 sm:mt-6">
            <div className="flex items-center bg-white rounded-lg overflow-hidden custom-box-shadow min-w-fit">
              <div className="p-4 ">
                {/* <PiBankDuotone size={30} className="text-[#00006B]" /> */}
                <img src={BankIcon} alt="" className="w-7 sm:w-10" />
              </div>
              <div className="px-4">
                {bankBalLoad ? (
                  <p className="text-lg">LOADING...</p>
                ) : (
                  <p className="text-lg sm:text-3xl font-bold text-[#00006B] mb-1 sm:mb-3">
                    {bankBal || 0}
                  </p>
                )}
                <h3 className="text-xs sm:text-sm font-bold">A/C Balance</h3>
              </div>
              <div
                className="p-4  cursor-pointer"
                onClick={() => checkbalance()}
              >
                <FiRefreshCw size={24} className="text-[#00006B]" />
              </div>
            </div>
            <AgentsSummary
              agents={data.length}
              title={"Total Transactions"}
              icon={BankIcon}
            />
            <AgentsSummary
              agents={data
                .filter(
                  (data) => data.object_transfer.metaData.status === "SUCCESS"
                )
                .reduce(
                  (total, transaction) => total + parseInt(transaction.amount),
                  0
                )}
              title={"Total Amount"}
              icon={BankIcon}
            />
          </div>
          {/** Agents data table  */}
          <div className="mt-3 sm:mt-10">
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationComponent={Pagination}
                progressPending={load}
                progressComponent={<Loader />}
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
              />
            </StyleSheetManager>
          </div>
        </>
      )}
    </main>
  );
}

export default Banking;
