const TransactionFilters = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="">
      <div className="relative flex w-full items-stretch ">
        <input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search"
          value={filterText}
          onChange={onFilter}
          className="relative m-0  block  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-black-100 dark:focus:border-primary h-[35px] sm:h-[42px]"
        />

        <button
          className="relative z-[2] flex items-center rounded-r bg-primary px-2 py-2 text-xs font-medium uppercase leading-tight text-white shadow-md transition  ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg h-[35px] sm:h-[42px]"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={onClear}
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </>
);

export default TransactionFilters;
