import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import Datepicker from "react-tailwindcss-datepicker";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/model/PageTitle";
import PrintInvoicePDF from "../../components/transactions/PrintInvoicePDF";
import TransactionCard from "../../components/transactions/TransactionCard";
import apiClient from "../../service/apiClient";
import { FETCH_AGENT_TRANSACTIONS } from "../../service/apiConstants";

import { useQuery } from "react-query";
import { utils, writeFile } from "xlsx";
import TransactionBarChart from "../../components/transactions/TransactionBarChart";
import TransactionFilters from "../../components/transactions/TransactionFilters";
import { getDateString } from "../../service/helper";

import { toast } from "react-toastify";
import { StyleSheetManager } from "styled-components";
import downloadIcon from "../../assets/svg/download.svg";
import Pagination from "../../components/Pagination/Pagination";
import { shouldForwardProp } from "../../service/shouldForwardProp";

//export components
const Export = ({ onExport }) => (
  <button
    className="bg-primary text-sm sm:text-base text-white hover:bg-blue-700 text-white font-bold items-center px-2 sm:py-2 sm:px-4 rounded font-normal h-[35px] sm:h-[42px] flex gap-1 sm:gap-2"
    onClick={(e) => onExport(e.target.value)}
  >
    <img
      src={downloadIcon}
      alt=""
      className=" w-4 h-4 sm:w-6 sm:h-6min-w-fit"
    />
    <span>Export</span>
  </button>
);

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="mb-3">
      <div className="relative mb-4 flex w-full flex-wrap items-stretch">
        <input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search"
          value={filterText}
          onChange={onFilter}
          className="relative m-0  block  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
        />

        <button
          className="relative z-[2] flex items-center rounded-r bg-primary hover:bg-blue-700 px-2 py-2 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={onClear}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </>
);

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {},
  },
};

const AgentTransactions = () => {
  // use for getting data from server
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  // filter through date
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  });

  // filter through search bar
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // useQuery code

  const { data: qdata } = useQuery("repoData", async () => {
    setLoad(true);
    await apiClient
      .get(FETCH_AGENT_TRANSACTIONS)
      .then((response) => {
        setLoad(false);

        if (response.status === 200) {
          setData(response.data.response.transactions_array);
          return;
        }
        if (response.status === 202) {
          setData(response.data.response.transactions_array);
          toast(response.data.message, {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
          return;
        }
        toast(response.data.message, {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      })
      .catch((error) => {
        setLoad(false);
        toast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Somthing wrong",
          {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          }
        );
      });
  });

  //sort function for date column

  const dateSortFun = (rowA, rowB) => {
    const dateA = new Date(rowA.created_At);
    const dateB = new Date(rowB.created_At);
    if (dateA > dateB) {
      return 1;
    }
    if (dateB > dateA) {
      return -1;
    }

    return 0;
  };

  // setting columns for table view
  const columns = [
    {
      name: "Date",
      selector: (row) => getDateString(row.created_At),
      sortable: "true",
      sortFunction: dateSortFun,
      grow: 2,
    },
    {
      name: "AirPayID",
      selector: (row) => row.OrderKeyId,
      grow: 4,
    },
    {
      name: "Order Amount",
      selector: (row) => <span>&#8377;{row.OrderAmount}</span>,
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: "Beneficiary Amount",
      selector: (row) => <span>&#8377;{row?.benificary_details?.Amount}</span>,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "OrderId",
      selector: (row) => row.OrderId,
      width: "200px",
      center: true,
    },
    {
      name: "Customer data",
      selector: (row) => {
        if (_.isEmpty(row.Customer_id)) {
          return "Not Availabel";
        }
        return `${row.Customer_id.Customer_data.FirstName} ${row.Customer_id.Customer_data.LastName}`;
      },
      width: "200px",
      center: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (
          row.OrderPaymentStatus == "200" ||
          row.OrderPaymentStatus == "201"
        ) {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-green-200 text-green-800">
              Success
            </span>
          );
        }
        return (
          <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
            Fail
          </span>
        );
      },
      width: "130px",
      center: true,
    },
    {
      name: "Payment method",
      selector: (row) => {
        if (row.PaymentMethod) {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-purple-200 text-purple-800">
              {row.PaymentMethod === "pg" ? "Credit Card" : row.PaymentMethod}
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
              Not available
            </span>
          );
        }
      },
      width: "170px",
      center: true,
    },
    {
      name: "Ref No.",
      selector: (row) => {
        if (_.isEmpty(row.PaymentTransactionRefNo)) {
          return (
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
              Not available
            </span>
          );
        }
        return <span>{row.PaymentTransactionRefNo}</span>;
      },
    },
    {
      name: "Benificary Id",
      selector: (row) => row.benificary_details.paymentInfo.beneficier_id,
      center: true,
      grow: 7,
    },
    {
      name: "Card No",
      selector: (row) => {
        if (row.paymentDetails.CARD_NUMBER) {
          return <span>{row.paymentDetails.CARD_NUMBER}</span>;
        }
        return (
          <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-200 text-red-800">
            Not available
          </span>
        );
      },
      width: "170px",
      center: true,
    },
    {
      name: "Bank Name",
      selector: (row) => {
        if (row?.paymentDetails?.BANKNAME) {
          return <span>{row?.paymentDetails?.BANKNAME}</span>;
        }
        return "Not Available";
      },
      width: "200px",
      center: true,
    },
    {
      name: "Mobile",
      selector: (row) => {
        if (_.isEmpty(row.Customer_id)) {
          return <span>Not Available</span>;
        }
        return `${row?.Customer_id?.mobile}`;
      },
      width: "150px",
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="w-full ">
            <PrintInvoicePDF row={row} />,
          </div>
        </>
      ),
    },
  ];

  //date filter logic

  let filteredData = [...data].reverse();
  if (!_.isEmpty(dateRange.startDate) && !_.isEmpty(dateRange.endDate)) {
    const startDate = new Date(dateRange.startDate);
    startDate.setUTCHours(0, 0, 0, 0);
    const endDate = new Date(dateRange.endDate);
    endDate.setUTCHours(23, 59, 59, 999);
    filteredData = filteredData.filter((item) => {
      const itemDate = new Date(item.created_At);
      return (
        startDate.getTime() <= itemDate.getTime() &&
        itemDate.getTime() <= endDate.getTime()
      );
    });
  }

  //filter logic
  if (filterText != "") {
    filteredData =
      filterText !== ""
        ? filteredData.filter((item) => {
            return (
              item.OrderKeyId.toLowerCase().includes(
                filterText.toLowerCase()
              ) ||
              item.OrderId.toLowerCase().includes(filterText.toLowerCase()) ||
              item.OrderAmount.toLowerCase().includes(filterText.toLowerCase())
            );
          })
        : filteredData;
    filteredData.reverse();
  }
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const handleValueChange = (newValue) => {
      setDateRange(newValue);
    };

    return (
      <div className="w-full flex flex-col xl:flex-row justify-between items-start">
        <h3 className="mb-2">Transaction Admin panel</h3>
        <div className="flex flex-col lg:flex-row item-stratch flex-wrap gap-3 sm:gap-4 md:gap-5 items-center md:items-start items-center">
          <div className="w-[265px]">
            <Datepicker
              placeholder={"Select Date"}
              value={dateRange}
              onChange={handleValueChange}
              useRange={false}
              maxDate={new Date()}
              primaryColor={"blue"}
              inputClassName="w-[265px] px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base h-[35px] sm:h-[42px] rounded-md focus:ring-0 font-normal bg-white-100 dark:placeholder:text-black-100"
              toggleClassName="absolute bg-primary hover:bg-blue-700 rounded-r-md text-white right-0 h-full px-2 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
            />
          </div>
          <div>
            <TransactionFilters
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </div>
          <div>
            <Export onExport={() => downloadCSV(filteredData)} />
          </div>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, dateRange, filteredData]);

  function downloadCSV(array) {
    const newArray = array
      .filter(
        (data) =>
          data.OrderPaymentStatus === "200" || data.OrderPaymentStatus === "201"
      )
      .map((item) => {
        return {
          AIRPAYID: item?.OrderKeyId,
          OrderAmount: item?.OrderAmount,
          BeneficiaryAmount: item?.benificary_details?.Amount,
          OrderId: item?.OrderId,
          CustomerData: item?.paymentDetails?.CUSTOMER,
          Status: item?.OrderPaymentStatusText,
          PaymentMethod: item?.PaymentMethod,
          RefNo: item?.PaymentTransactionRefNo,
          BenificaryId: item?.benificary_details?.paymentInfo?.beneficier_id,
          CardNo: item?.paymentDetails?.CARD_NUMBER,
          BankName: item?.paymentDetails?.BANKNAME,
          Mobile: item?.Customer_id?.mobile,
          Date: getDateString(item?.created_At),
        };
      });

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(newArray);
    const columnToSetAsNumber = "C"; // Column B is the Age column
    const columnRange = utils.decode_range(ws["!ref"]);
    for (
      let rowIdx = columnRange.s.r + 1;
      rowIdx <= columnRange.e.r;
      rowIdx++
    ) {
      const cellRef1 = utils.encode_cell({
        r: rowIdx,
        c: utils.decode_col("B"),
      });
      ws[cellRef1].t = "n";
      const cellRef = utils.encode_cell({
        r: rowIdx,
        c: utils.decode_col(columnToSetAsNumber),
      });
      ws[cellRef].t = "n"; // Set the cell type for the entire column to 'n' (number)
    }
    utils.book_append_sheet(wb, ws, "export");
    writeFile(wb, "export.xlsx");
  }

  return (
    <main className="p-8 w-full">
      <PageTitle title="Agent Transactions" />
      {load ? (
        <Loader />
      ) : (
        <>
          <h2 className=" md:text-center font-semibold mb-2 text-primary text-bold">
            Transaction statistics
          </h2>
          <div className="flex flex-wrap justify-start gap-3 sm:gap-6 mb-6">
            <TransactionCard data={data.length} title={"Total Transactions"} />
            <TransactionCard
              data={
                [
                  ...data.filter(
                    (row) =>
                      row.OrderPaymentStatus === "200" ||
                      row.OrderPaymentStatus === "201"
                  ),
                ].length
              }
              title={"Total successful Transactions"}
            />
            <TransactionCard
              number={true}
              data={data
                .filter(
                  (data) =>
                    data.OrderPaymentStatus === "200" ||
                    data.OrderPaymentStatus === "201"
                )
                .reduce(
                  (total, transaction) =>
                    total +
                    parseInt(transaction.benificary_details.TotalAmount),
                  0
                )}
              title={"Total Amount"}
            />
            <TransactionCard
              number={true}
              data={data
                .filter(
                  (data) =>
                    data.OrderPaymentStatus === "200" ||
                    data.OrderPaymentStatus === "201"
                )
                .reduce(
                  (total, transaction) =>
                    total + parseInt(transaction.benificary_details.Amount),
                  0
                )}
              title={"Beneficiary Credited Amount"}
            />
          </div>

          <hr />
          <TransactionBarChart
            amountData={[...data.map((data) => data.OrderAmount)]}
            createdDates={[
              ...data.map((data) => {
                const dateString = data.created_At;
                const dateObject = new Date(dateString);

                // Get the date portion as a string (YYYY-MM-DD)
                const date = dateObject.toISOString().split("T")[0];
                return date;
              }),
            ]}
          />
          <div className="user-table">
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationComponent={Pagination}
                progressPending={load}
                progressComponent={<Loader />}
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
              />
            </StyleSheetManager>
          </div>
        </>
      )}
    </main>
  );
};

export default AgentTransactions;
