import React from "react";

function PageTitle(props) {
  return (
    <h2
      className={`text-lg ${!props?.bold ? "" : "font-semibold"} mb-4 ${
        props?.className
      }`}
    >
      {props?.title}
    </h2>
  );
}

export default PageTitle;
