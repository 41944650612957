import React from "react";
import { createBrowserRouter, defer } from "react-router-dom";
import { AuthLayout } from "../layouts/AuthLayout";
import { HomeLayout } from "../layouts/HomeLayout";
import HomePage from "../pages/Home";
import { ProtectedLayout } from "../layouts/ProtectedLayout";
import AgentsManage from "../pages/AgentsManage";
import AddAgent from "../pages/AddAgent";
import UserLayout from "../layouts/UserLayout";
import { elements } from "chart.js";
import ManualTransfer from "../components/Banking/ManualTransfer";
import Banking from "../pages/Banking";
import Reports from "../pages/Reports";
import AgentTransactions from "../components/AgentManagement/AgentTransactions";
import AgentPayouts from "../components/AgentManagement/AgentPayouts";
import AgentWithdrawPayouts from "../components/AgentManagement/AgentWithdrawPayouts";

const UserDetails = React.lazy(() => import("../components/Users/UserDetails"));
const Login = React.lazy(() => import("../components/Login/Login"));
const ForgotPassword = React.lazy(() =>
  import("../components/Login/ForgotPassword")
);
const ResetPassWord = React.lazy(() =>
  import("../components/Login/ResetPassWord")
);
const RentPayouts = React.lazy(() => import("../pages/RentPayouts"));
const Users = React.lazy(() => import("../pages/Users"));
const Transactions = React.lazy(() => import("../pages/Transactions"));

const ErrorPage = React.lazy(() => import("../components/model/ErrorPage"));

const GetNonKyc = React.lazy(() => import("../pages/NonKycUsers"));

const getUserData = async () => {
  const user = await window.localStorage.getItem("user");
  return user;
};
export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    loader: () => defer({ userPromise: getUserData() }),
    children: [
      {
        path: "",
        element: <HomeLayout />,
        children: [
          {
            path: "",
            element: <HomePage />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/forgotpassword",
            element: <ForgotPassword />,
          },

          {
            path: "/resetpassword",
            element: <ResetPassWord />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: <ProtectedLayout />,
        children: [
          // {
          //   path: "",
          //   element: <RentPayouts />,
          // },
          {
            path: "rent-payouts",
            element: <RentPayouts />,
          },
          {
            path: "users/:clientid/:phonenumber",
            element: <UserDetails />,
          },
          {
            path: "users",
            // element: <Users />,
            element: <UserLayout />,
            children: [
              {
                path: "",
                element: <Users />,
              },
              {
                path: "getnonkyc",
                element: <GetNonKyc />,
              },
            ],
          },
          {
            path: "transactions",
            element: <Transactions />,
          },
          {
            path: "agentsmanagement",
            element: <AgentsManage />,
            children: [
              {
                path: "addAgent",
                element: <AddAgent />,
              },
              {
                path: "agenttransactions",
                element: <AgentTransactions />,
              },
              {
                path: "agentpayouts",
                element: <AgentPayouts />,
              },
              {
                path: "agentwithdrawpayouts",
                element: <AgentWithdrawPayouts />,
              },
            ],
          },
          {
            path: "banking",
            element: <Banking />,
            children: [
              {
                path: "manualtransfer",
                element: <ManualTransfer />,
              },
            ],
          },
          {
            path: "addAgent",
            element: <AddAgent />,
          },

          {
            path: "reports",
            element: <Reports />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);
