import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { MdOutlineExpandLess } from "react-icons/md";
import { MdOutlineExpandMore } from "react-icons/md";

function MenuItem(props) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const activeButtonStyles = "active bg-primary text-white";
  const buttonStyles = `flex  ${
    props.isSideBarExpand ? "gap-2" : ""
  }   hover:bg-primary text-[#6A727A] hover:text-white py-3 rounded-lg cursor-pointer`;

  const handleClick = () => {
    setOpen(!open);
    props.setIsSideBarExpand(true);
  };

  return (
    <div>
      {props.subMenu ? (
        <div>
          <li onClick={handleClick} className="mt-2">
            <NavLink
              to={props.menu.path}
              className={({ isActive, isPending }) => {
                return `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "active bg-primary text-white"
                    : ""
                } flex items-center gap-2 py-3 text-base font-normal rounded-lg cursor-pointer hover:bg-primary hover:text-white
                ${
                  props.isSideBarExpand
                    ? "pl-3 pr-2 min-w-[144.17px]"
                    : "p-5 min-w-[66px]"
                }  `;
              }}
            >
              <span className="text-2xl ">{props.menu.src}</span>
              {props.isSideBarExpand ? (
                <span className={`origin-left  text-sm grow`}>
                  {props.menu.title}
                </span>
              ) : null}

              {props.isSideBarExpand ? (
                <span className="text-2xl ">
                  {open ? <MdOutlineExpandMore /> : <MdOutlineExpandLess />}
                </span>
              ) : null}
            </NavLink>
          </li>
          {open && props.subMenu && (
            <ul className="text-[#53545C]">
              {props.isSideBarExpand &&
                props.subMenu.map((menu, index) => (
                  <li key={index} className=" list-disc ml-12">
                    <NavLink
                      to={menu.path}
                      className={({ isActive, isPending }) => {
                        return `${
                          isPending
                            ? "pending"
                            : isActive
                            ? activeButtonStyles
                            : ""
                        } flex items-center gap-2 py-3 text-base font-normal rounded-lg cursor-pointer hover:bg-primary hover:text-white
                        ${
                          props.isSideBarExpand
                            ? "pl-3 pr-2 min-w-[144.17px] mt-1"
                            : "p-5 min-w-[66px]"
                        }  `;
                      }}
                    >
                      {props.isSideBarExpand ? (
                        <span className={`origin-left  text-sm`}>
                          {menu.title}
                        </span>
                      ) : null}
                    </NavLink>
                  </li>
                ))}
            </ul>
          )}
        </div>
      ) : (
        <li className="mt-2">
          <NavLink
            to={props.menu.path}
            className={({ isActive, isPending }) => {
              return `${
                isPending ? "pending" : isActive ? activeButtonStyles : ""
              }  
              ${
                props.isSideBarExpand
                  ? "pl-3 min-w-[144.17px] "
                  : "p-5 min-w-[66px]"
              } flex items-center gap-2 py-3 text-base font-normal rounded-lg cursor-pointer hover:bg-primary hover:text-white `;
            }}
          >
            <span className="text-2xl">{props.menu.src}</span>

            {props.isSideBarExpand ? (
              <span className={`origin-left  text-sm hover:block`}>
                {props.menu.title}
              </span>
            ) : null}
          </NavLink>
        </li>
      )}
    </div>
  );
}

export default MenuItem;
