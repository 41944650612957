import React from "react";

function TransactionPopupField({ title, data }) {
  return (
    <div className="flex items-center mb-2 ">
      <span className="text-sm mr-2 w-[150px] align-middle">{title}</span>
      <span className="text-sm grow border border-black/10 p-1">{data}</span>
    </div>
  );
}

export default TransactionPopupField;
