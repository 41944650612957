export const BASE_URL =process.env.REACT_APP_API_BASE_URL || 
//"https://apiv2.payinstacard.com"
  "http://localhost:4000";

export const GET_USER_ROLE = "/api/v2/getUserRole";
export const GET_USERS = "admin/users/get-users";
export const GET_ONE_USER = "admin/users/get-one-user";
export const GET_TRANSACTIONS =
  "admin/transaction/get-all-transaction?secrets=6L03co07UUopl2P7v4ZNq82jeKU08S7x";

export const KYCSTATUS = "admin/kycStatus";
export const USERSTATUS = "admin/userStatus";
export const PAYMENTSTATUS = "admin/paymentStatus";

export const AGENTSTATUS = "admin/agentStatus";

export const EDIT_COMMISION = "/users/edit-commision";
export const EDIT_LIMIT_ASSIGNED = "/api/v2/dashboarding/change-limit-assigned";

export const ADD_AGENT = "/api/v2/agents/tempData/addFormTemptData";
export const UPDATE_AGENT = "/api/v2/agents/tempData/updateFormTemptData";
export const AGENTS = "/api/v2/agents/tempData/formTemptData";

export const SUBMIT_AGENT = "api/v2/agents/agents/addAgent";
export const FETCH_AGENT = "api/v2/agents/agents/fetchAll";
export const EDIT_AGENT_COMMISION =
  "/api/v2/agents/agents/edit-agent-commission/";

export const TRANSFER_LIST = "admin/transfer/fetch-transfer";

// TEST
// export const TRANSFER_APPROVE = "admin/transfer/test-create-transfer/";
// export const TRANSFER_REJECT = "admin/transfer/test-reject-transfer/";
export const TRANSFER_APPROVE = "admin/transfer/create-transfer/";

//export const TRANSFER_APPROVE = "/transfer/create-transfer-new/";
export const TRANSFER_REJECT = "admin/transfer/reject-transfer/";

export const AGENT_TRANSFER_APPROVE = "/transfer/agent-create-transfer-new/";
export const AGENT_TRANSFER_REJECT = "/transfer/agent-reject-transfer-new/";

//Withdraw fund transfer
export const AGENT_WITHDRAW_TRANSFER_APPROVE =
  "/transfer/agent-withdraw-transfer-new";

export const AGENT_WITHDRAW_REJECT_TRANSFER =
  "/transfer/agent-withdraw-reject-transfer";

export const ADMIN_LOGIN_PREVENTION = "/users/admin-login-prevention";

export const NON_KYC_USER = "admin/getnon-kyc-users";

export const PAN_VERIFICATION = "/api/v2/agents/verify/pan";
export const AADHAR_VERIFICATION = "/api/v2/agents/verify/aadhar";
export const OTP_VERIFICATION = "/api/v2/agents/verify/otpVerify";
export const BANK_VERIFICATION = "/api/v2/agents/verify/bankVerify";

export const FETCH_AGENT_TRANSACTIONS =
  "/api/v2/agents/agents/fetch-agent-transactions?secrets=6L03co07UUopl2P7v4ZNq82jeKU08S7x";
export const FETCH_AGENT_TRANSFERS =
  "/api/v2/agents/agents/fetch-agent-transfers";
export const FETCH_AGENT_WITHDRAW_TRANSFERS = "/payment/withdrawals";

export const CHECK_ACCOUNT_BALANCE = "/admin/get-balance";
export const CHECK_PAYMENT_MODE_STATUS = "/users/get-settings";

export const FETCH_TRANSFER = "/manual-transfer/fetch-transfers";

//for manual fund transfer
export const VELIDATION_CHECK = "/manual-transfer/validation-check";
export const MANUAL_TRANSFER = "/manual-transfer/transfer-funds";
export const GET_OTP = "/manual-transfer/send-otps";
export const RESEND_OTP = "/manual-transfer/resend-otp";
export const VERIFY_OTP = "/manual-transfer/verify-otp";

export const GET_REPORT = "/admin/get-reports-rent";
export const TOGGLE_PAYMENTS = "/settings/payment/status/toggle";
