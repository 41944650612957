import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

function UserLayout(props) {
  const location = useLocation();
  return (
    <div className="bg-white p-3 min-[450px]:p-9 rounded-lg">
      <div className="border-b border-[#989898] text-lg py-4">
        <div className="ml-4 text-[#989898]">
          <NavLink
            to="/dashboard/users"
            // className={({ isActive, isPending }) => {
            //   return `${
            //     isPending ? "pending" : isActive ? "active  text-primary" : ""
            //   } mr-6 `;
            // }}
            className={
              location.pathname === "/dashboard/users"
                ? "content_border text-primary font-bold mr-6 py-4"
                : ""
            }
          >
            Manage Users
          </NavLink>
          <NavLink
            to="/dashboard/users/getnonkyc"
            // className={({ isActive, isPending }) => {
            //   return `${
            //     isPending ? "pending" : isActive ? "active  text-primary" : ""
            //   }`;
            // }}
            className={
              location.pathname === "/dashboard/users/getnonkyc"
                ? "content_border text-primary font-bold ml-6 py-4"
                : ""
            }
          >
            Non - KYC Users
          </NavLink>
        </div>
      </div>

      <Outlet></Outlet>
    </div>
  );
}

export default UserLayout;
