import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../contaxt/AuthContaxt";
import Sidebar from "../components/SideBar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Loader from "../components/Loader/Loader";

export const ProtectedLayout = () => {
  const { user, loading, logoutCurrentUser } = useAuth();
  const outlet = useOutlet();
  console.log("prote===>", user);

  if (user) {
    if (!user && !user?.role?.admin && !loading) {
      return <Navigate to="/login" />;
    } else if (!user?.role?.admin) {
      logoutCurrentUser();
    }
  } else {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {user?.role?.admin ? (
        <>
          <div className="flex h-screen bg-gray-100">
            <div className="">
              <Sidebar />
            </div>
            <div className="overflow-auto w-full">
              <Navbar />
              <div className="m-6 bg-white rounded-xl">
                {loading ? <Loader /> : outlet}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navigate to="/login" />
        </>
      )}
    </>
  );
};
