import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

//icon
import { BsArrowLeftCircle, BsBank2 } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import { MdUsb } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { PiUserListDuotone } from "react-icons/pi";
import { BiSolidReport } from "react-icons/bi";

import LogoSvg from "../../assets/svg/Payinsta_trademark_Logo.svg";
import HamburgerButton from "../HamburgerMenuButton/HamburgerButton";

import { ReactComponent as DashboardSvg } from "../../assets/svg/DashBoard.svg";
import { ReactComponent as Customers } from "../../assets/svg/Sales.svg";
// import { ReactComponent as Profile } from "../../assets/svgProfiles.svg";
import { ReactComponent as LogOut } from "../../assets/svg/logout.svg";
import { ReactComponent as Transaction } from "../../assets/svg/transactionIcon.svg";
import { ReactComponent as AgentIcon } from "../../assets/svg/AgentIcon.svg";
import { ReactComponent as BankIcon } from "../../assets/svg/BankIcon.svg";
import { ReactComponent as Report } from "../../assets/svg/Report.svg";

import MenuItem from "./MenuItem";
import { useAuth } from "../../contaxt/AuthContaxt";

function Sidebar() {
  const [isSideBarExpand, setIsSideBarExpand] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const auth = useAuth();
  const navigation = useNavigate();

  const logout = () => {
    auth.logoutCurrentUser();
    navigation("/");
  };

  const Menus = [
    {
      title: "Rent-Payouts",
      path: "/dashboard/rent-payouts",
      src: <DashboardSvg />,
    },
    {
      title: "Transactions",
      path: "/dashboard/transactions",
      src: <Transaction />,
    },
    {
      title: "Users",
      path: "/dashboard/users",
      src: <Customers />,
    },

    {
      title: "Agents Management",
      path: "/dashboard/agentsmanagement",
      src: <AgentIcon />,
      subMenu: [
        {
          title: "Agent Transactions",
          path: "/dashboard/agentsmanagement/agenttransactions",
        },
        {
          title: "Agent Payouts",
          path: "/dashboard/agentsmanagement/agentpayouts",
        },
        {
          title: "Agent withdraw Payouts",
          path: "/dashboard/agentsmanagement/agentwithdrawpayouts",
        },
      ],
    },
    {
      title: "Banking",
      path: "/dashboard/banking",
      src: <BankIcon />,
    },

    {
      title: "Reports",
      path: "/dashboard/reports",
      src: <Report />,
    },
  ];

  return (
    <>
      <div
        className={`${
          isSideBarExpand ? "w-max	p-4" : "w-fit p-5"
        } bg-white shadow-lg h-screen sticky top-0 hidden sm:block relative duration-300  border-r  `}
      >
        <div
          className={`overflow-auto h-full scroll flex flex-col justify-between ${
            isSideBarExpand ? "px-1" : "px-2"
          }`}
        >
          <div>
            <BsArrowLeftCircle
              className={`${
                !isSideBarExpand && "rotate-180"
              } absolute text-2xl bg-white  cursor-pointer top-9 -right-3`}
              onClick={() => setIsSideBarExpand(!isSideBarExpand)}
            />
            <NavLink to="/">
              <div
                className={`flex ${isSideBarExpand && "gap-x-4"} items-center`}
              >
                <img src={LogoSvg} alt="" className="pl-2 w-20" />
              </div>
            </NavLink>
            <ul className="pt-3 text-[#53545C]">
              {isSideBarExpand && <div>Dashboard </div>}
              {Menus.map((menu, index) => (
                <MenuItem
                  menu={menu}
                  src={menu.src}
                  title={menu.title}
                  path={menu.path}
                  subMenu={menu.subMenu}
                  key={index}
                  setIsSideBarExpand={setIsSideBarExpand}
                  isSideBarExpand={isSideBarExpand}
                />
              ))}
            </ul>
          </div>
          <div>
            <ul
              // className="px-6 pb-10 flex item-bottom"
              className={`${
                isSideBarExpand ? " flex item-bottom justify-center" : "py-0"
              } border-[#EFF0F2] border-t-[1px]`}
            >
              <li
                // className="flex w-full gap-2 hover:bg-primary text-[#6A727A] hover:text-white py-3 pl-3 rounded-lg cursor-pointer mt-2"
                className={`${
                  isSideBarExpand
                    ? "pl-3 min-w-[144.17px] w-[80%]"
                    : "p-5 min-w-[66px]"
                } flex gap-2 hover:bg-primary text-[#6A727A] hover:text-white py-3 rounded-lg cursor-pointer `}
                onClick={logout}
              >
                <span>
                  {/* <img src={LogOut} alt="" className="min-w-min" /> */}
                  <LogOut />
                </span>
                {/* <span className="text-base">Log Out</span> */}
                {isSideBarExpand ? (
                  <span className={`origin-left text-base hover:block`}>
                    {/* {props.menu.title} */}Log Out
                  </span>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}

      {/* <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? "flex" : "hidden"
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-primary dark:bg-primary drop-shadow md rounded-xl`}
        >
          {Menus.map((menu, index) => (
            <NavLink
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  "bg-gray-200 dark:bg-gray-700"
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </NavLink>
          ))}
        </div>
      </div> */}
    </>
  );
}

export default Sidebar;
