import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
 apiKey: "AIzaSyCazUjzmtDEDtT2MD7JvsfEFmWtGBuIvB8",
  authDomain: "pic-migration-instance-prod.firebaseapp.com",
  projectId: "pic-migration-instance-prod",
  storageBucket: "pic-migration-instance-prod.appspot.com",
  messagingSenderId: "1014565268166",
  appId: "1:1014565268166:web:bf3432231014af299cc5c7",
  measurementId: "G-2CWG38HV5K"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
export const getAccessToken = async () => {
  return await auth?.currentUser?.getIdToken(true);
};
export const logout = () => {
  return signOut(auth);
};
// const analytics = getAnalytics(app);
export default app;
