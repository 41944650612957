import React, { useState } from "react";
import HamburgerButton from "./HamburgerMenuButton/HamburgerButton";
import { AiOutlineHome } from "react-icons/ai";
import { MdUsb } from "react-icons/md";
import { PiUserListDuotone } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { NavLink, useLocation } from "react-router-dom";
import { BiSolidReport } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import { LinearScale } from "chart.js";

function MobileMenu(props) {
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const Menus = [
    {
      title: "Rent-Payouts",
      path: "/dashboard/rent-payouts",
      src: <AiOutlineHome />,
    },
    { title: "Users", path: "/dashboard/users", src: <CgProfile /> },
    {
      title: "Transactions",
      path: "/dashboard/transactions",
      src: <MdUsb />,
    },
    {
      title: "Agents Management",
      path: "/dashboard/agentsmanagement",
      src: <PiUserListDuotone />,
      subMenu: [
        {
          title: "Agent Transactions",
          path: "/dashboard/agentsmanagement/agenttransactions",
        },
        {
          title: "Agent Payouts",
          path: "/dashboard/agentsmanagement/agentpayouts",
        },
        {
          title: "Agent withdraw Payouts",
          path: "/dashboard/agentsmanagement/agentwithdrawpayouts",
        },
      ],
    },
    {
      title: "Banking",
      path: "/dashboard/banking",
      src: <BsBank2 />,
    },

    {
      title: "Reports",
      path: "/dashboard/reports",
      src: <BiSolidReport />,
    },
  ];

  return (
    <div className="block sm:hidden">
      <div className="">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? "flex" : "hidden"
          } absolute z-50 flex-col items-center self-end py-8 mt-8 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-primary dark:bg-primary drop-shadow md rounded-xl`}
        >
          {/* {Menus.map((menu, index) => (
            <NavLink
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  "bg-gray-200 dark:bg-gray-700"
                } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </NavLink>
          ))} */}

          {Menus.map((menu, index) => {
            if (menu?.subMenu) {
              return (
                <>
                  {" "}
                  <NavLink
                    to={menu.path}
                    key={index}
                    onClick={() => setMobileMenu(false)}
                  >
                    <span
                      className={` ${
                        location.pathname === menu.path &&
                        "bg-gray-200 dark:bg-gray-700"
                      } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
                    >
                      {menu.title}
                    </span>
                  </NavLink>
                  <ul className="text-center	" style={{ margin: "0px" }}>
                    {menu?.subMenu?.map((item, index) => (
                      <li className="mt-2" key={index}>
                        <NavLink
                          to={item?.path}
                          key={index}
                          onClick={() => setMobileMenu(false)}
                        >
                          <span
                            className={` ${
                              location.pathname === item?.subMenu?.path &&
                              "bg-gray-200 dark:bg-gray-700"
                            } p-2 rounded-xl font-normal text-sm hover:bg-gray-200 dark:hover:bg-gray-700`}
                          >
                            {item?.title}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              );
            }

            return (
              <NavLink
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    "bg-gray-200 dark:bg-gray-700"
                  } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
